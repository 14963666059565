import PasswordChangedForm from '@/components/Auth/Form/PasswordChangedForm'

const PasswordChangedPage = ({ texts }) => {
	return (
		<div className="loginForm-section">
			<div className="container">
				<PasswordChangedForm texts={texts} />
			</div>
		</div>
	)
}

export default PasswordChangedPage

import { useState, useEffect } from 'react'

import './styles.css'

const BlockInterests = ({ user, isOwn, onEdit }) => {
	const [interests, setInterests] = useState([])

	useEffect(() => {
		if (user) {
			setInterests(user.interests)
		}
	}, [user])

	return (		
		<div className="block block-interests">
			{isOwn && <div className="block-edit">
				{/*<Link to="/user/edit?block=interests">edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>Zainteresowania</h2>
				</div>
				<div className="block-interests-list">
					{interests.length !== 0 
						? <ul>
							{interests.map((item, index) => <li key={index}>{item.name}</li>)}
						</ul>
						: <span>(brak danych)</span>
					}
				</div>
			</div>
		</div>
	)
}

export default BlockInterests

import {
  GET_AUTH_PAGES_SUCCESS,
  GET_AUTH_PAGES_FAILURE,
  GET_AUTH_PAGE_SUCCESS,
  GET_AUTH_PAGE_FAILURE,
  GET_INTERESTS_SUCCESS,
  GET_INTERESTS_FAILURE,
} from '../actions/content'

const initialState = {
  authPages: [],
  authPage: null,
  interests: [],
  error: null,
}

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTH_PAGES_SUCCESS:
      return {
        ...state,
        authPages: action.payload,
        error: null
      } 
    case GET_AUTH_PAGES_FAILURE:
      return {
        ...state,
        authPages: [],
        error: action.payload
      }
    case GET_AUTH_PAGE_SUCCESS:
      return {
        ...state,
        authPage: action.payload,
        error: null
      } 
    case GET_AUTH_PAGE_FAILURE:
      return {
        ...state,
        authPage: null,
        error: action.payload
      }
    case GET_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: action.payload,
        error: null
      } 
    case GET_INTERESTS_FAILURE:
      return {
        ...state,
        interests: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default contentReducer

import { useState, useEffect } from 'react'
import BlockJob from './BlockJob'

const BlockWorks = ({ user, isOwn, onEdit }) => {
	const [works, setWorks] = useState([])

	useEffect(() => {
		if (user) {
			setWorks(user.works)
		}
	}, [user])

	const options = {
		title: 'Praca zawodowa',
		anchor: 'works',
		color: 'red',
		icon: 'shop',
	}

	return (
		<BlockJob items={works} options={options} isOwn={isOwn} onEdit={onEdit} />
	)
}

export default BlockWorks

const getMonth = (date) => {
	return String(date.getMonth() + 1).padStart(2, '0')
}

const getDay = (date) => {
	return String(date.getDate()).padStart(2, '0')
}

const getHours = (date) => {
	return String(date.getHours()).padStart(2, '0')
}

const getMinutes = (date) => {
	return String(date.getMinutes()).padStart(2, '0')
}

export const getCurrentDate = () => {
	const date = new Date()
    return `${date.getFullYear()}-${getMonth(date)}-${getDay(date)}`
}

export const formatDate = (value) => {
	if (value) {
		const date = new Date(value)
	    return `${date.getFullYear()}-${getMonth(date)}-${getDay(date)}`
	}
	return null
}

export const formatPeriod = (from, to) => {
	const parts = []
	for (const item of [from, to]) {
		if (item) {
			const date = new Date(item)
			parts.push(`${getMonth(date)}.${date.getFullYear()}`)
		} else {
			parts.push('teraz')
		}
	}
	const [start, end] = parts
	return `od ${start} do ${end}`
}

export const reverseDateFormat = (date, sepInput = '-', sepOutput = '/') => {
	return date.split(sepInput).reverse().join(sepOutput)
}

export const formatChatDatetime = (value) => {
	if (value) {
		const date = new Date(value)
	    return `${getDay(date)}.${getMonth(date)}.${date.getFullYear()}, g. ${getHours(date)}:${getMinutes(date)}`
	}
	return null
}

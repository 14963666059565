export const minSalary = 1000
export const maxSalary = 10000
export const salaryStep = 50

export const emptyFilters = {
	offer_type: '',
	city: '',
	activity_type: '',
	salary_from: minSalary,
	salary_to: maxSalary
}

export const offerTypes = {
	internship: 'staż',
	practice: 'praktyka',
	work: 'praca',
}

export const workSystems = {
    '1': 'praca zdalna',
    '2': 'praca w biurze',
    '3': 'model hybrydowy',
    '4': 'praca semi zdalna',
}

export const contractTypes = {
    '1': 'umowa o prace',
    '2': 'kontrakt B2B',
    '3': 'umowa zlecenie',
    '4': 'umowa o dzieło',
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getOrganizationById } from '@/actions/organization'
import BlockInfo from '@/components/Organization/BlockInfo'
import BlockAbout from '@/components/Organization/BlockAbout'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockProjects from '@/components/Organization/BlockProjects'

const OrganizationProfilePage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const viewOrganizationLoading = useSelector(state => state.organization.viewOrganizationLoading)
    const viewOrganization = useSelector(state => state.organization.viewOrganization)

    useEffect(() => {
        if (id) dispatch(getOrganizationById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!viewOrganization && !viewOrganizationLoading) {
        	navigate('/404')
        }
    }, [viewOrganizationLoading, viewOrganization, navigate])

	return (
		<>
			<BlockInfo organization={viewOrganization} />
			<BlockAbout organization={viewOrganization} />
			<BlockMembers entity={viewOrganization} title="członkowie" />
			<BlockProjects organization={viewOrganization} />
		</>
	)
}

export default OrganizationProfilePage

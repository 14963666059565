import api from './api'

class CompanyService
{
	async getCompanyById(id, token) {
		try {
			const response = await api.get(`/company/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getCompanyById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanies(id, token) {
		try {
			const response = await api.get(`/company/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanies][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyById(id, token) {
		try {
			const response = await api.get(`/company/my/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyOffers(id, token) {
		try {
			const response = await api.get(`/company/my/${id}/offers`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyOffers][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyOffer(company_id, offer_id, token) {
		try {
			const response = await api.get(`/company/my/${company_id}/offer/${offer_id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyOffer][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new CompanyService()

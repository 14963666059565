import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkRecoverToken, changePassword } from '@/actions/auth'
import ChangePasswordForm from '@/components/Auth/Form/ChangePasswordForm'

const ChangePasswordPage = ({ texts }) => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
  	const token = params.get('token')

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		(async () => {
			await dispatch(checkRecoverToken({ token }))
		})()
	}, [dispatch, token])

	const onSubmit = async (payload) => {
		const success = await dispatch(changePassword(payload))
		if (success) navigate('/password-changed')
	}

	return (		
		<div className="loginForm-section">
			<div className="container">
				<ChangePasswordForm texts={texts} onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default ChangePasswordPage

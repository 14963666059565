export const getFullName = (user) => {
	if (user && user.profile) {
		const { firstname, lastname } = user.profile
		return [firstname, lastname].join(' ')
	}
	return null
}

export const parseFullName = (fullname) => {
	if (fullname) {
		const namesArray = fullname.split(' ')
		if (namesArray.length === 2) {
			return {
				firstname: namesArray[0],
				lastname: namesArray[1]
			}
		}
	}
	return null
}

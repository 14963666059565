import { useState, useEffect, useRef } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCarouselPosition } from '@/utils/content.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import icon1 from '@/assets/images/offers/icon-1.svg'
import icon2 from '@/assets/images/offers/icon-2.svg'

const OfferItem = ({ company, offer }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	return (
		<div className="block-offers-item">
			<p className="block-offers-item_head"><span>staż</span>oferta reguralna</p>
			<div className="block-offers-item_image">
				<img src={getImage(company.logo_image)} width="" height="" alt="" />
			</div>
			<div className="block-offers-item_info">
				<p className="block-offers-item_company">{company.name}</p>
				<h3 className="block-offers-item_title">{offer.title}</h3>
				<p className="block-offers-item_city">{offer.city}, {offer.region}</p>
				<div className="block-offers-item_advantages">
					<p><img src={icon1} width="" height="" alt="" />{offer.work_system}</p>
					<p><img src={icon2} width="" height="" alt="" />{offer.contract_type}</p>
				</div>
				<div className="block-offers-item_data">
					{offer.specializations.map((item, index) => <p key={index}>{item}</p>)}
				</div>
				<div className="block-offers-item_button">
					<a className="btn btn-sm btn-secondary">OBEJRZYJ PROJEKT</a>
				</div>
			</div>
		</div>
	)
}

const BlockOffers = ({ company }) => {
	const [offers, setOffers] = useState([])
	const headingRef = useRef(null)

	useEffect(() => {
		if (company) {
			setOffers(company.offers)
		}
	}, [company])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        768: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 46,
            items: 2
        }
    }

	const handleChanged = (e) => {
		if (e.item) {
	        const { from, to, total } = getCarouselPosition(e.item)

			if (headingRef.current) {
				headingRef.current.textContent = `oferty ${from} i ${to} z ${total}`
			}
		}
	}

	if (!offers.length) {
		return null
	}

	return (
		<div className="block block-offers">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>Oferty pracy <span ref={headingRef} className="block-heading-other-sm"></span></h2>
				</div>
				<div className="block-offers-items">
					<OwlCarousel
						className='owl-offers owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
						onChanged={handleChanged}
					>
						{offers.map((offer, index) => 
							<OfferItem company={company} offer={offer} key={index} />
						)}
					</OwlCarousel>
				</div>
				<div className="block-offers-button">
					<a className="btn btn-primary">zobacz wszystkie oferty</a>
				</div>
			</div>
		</div>
	)
}

export default BlockOffers

import CompanyService from '@/services/company.service'

export const GET_VIEW_COMPANY_REQUEST = 'GET_VIEW_COMPANY_REQUEST'
export const GET_VIEW_COMPANY_SUCCESS = 'GET_VIEW_COMPANY_SUCCESS'
export const GET_VIEW_COMPANY_FAILURE = 'GET_VIEW_COMPANY_FAILURE'
export const GET_MY_COMPANIES_SUCCESS = 'GET_MY_COMPANIES_SUCCESS'
export const GET_MY_COMPANIES_FAILURE = 'GET_MY_COMPANIES_FAILURE'
export const GET_MY_COMPANY_REQUEST = 'GET_MY_COMPANY_REQUEST'
export const GET_MY_COMPANY_SUCCESS = 'GET_MY_COMPANY_SUCCESS'
export const GET_MY_COMPANY_FAILURE = 'GET_MY_COMPANY_FAILURE'
export const GET_MY_COMPANY_OFFERS_REQUEST = 'GET_MY_COMPANY_OFFERS_REQUEST'
export const GET_MY_COMPANY_OFFERS_SUCCESS = 'GET_MY_COMPANY_OFFERS_SUCCESS'
export const GET_MY_COMPANY_OFFERS_FAILURE = 'GET_MY_COMPANY_OFFERS_FAILURE'
export const GET_MY_COMPANY_OFFER_REQUEST = 'GET_MY_COMPANY_OFFER_REQUEST'
export const GET_MY_COMPANY_OFFER_SUCCESS = 'GET_MY_COMPANY_OFFER_SUCCESS'
export const GET_MY_COMPANY_OFFER_FAILURE = 'GET_MY_COMPANY_OFFER_FAILURE'

export const getViewCompanyRequest = () => ({ type: GET_VIEW_COMPANY_REQUEST })
export const getViewCompanySuccess = (data) => ({ type: GET_VIEW_COMPANY_SUCCESS, payload: data })
export const getViewCompanyFailure = (error) => ({ type: GET_VIEW_COMPANY_FAILURE, payload: error })
export const getMyCompaniesSuccess = (data) => ({ type: GET_MY_COMPANIES_SUCCESS, payload: data })
export const getMyCompaniesFailure = (error) => ({ type: GET_MY_COMPANIES_FAILURE, payload: error })
export const getMyCompanyRequest = () => ({ type: GET_MY_COMPANY_REQUEST })
export const getMyCompanySuccess = (data) => ({ type: GET_MY_COMPANY_SUCCESS, payload: data })
export const getMyCompanyFailure = (error) => ({ type: GET_MY_COMPANY_FAILURE, payload: error })
export const getMyCompanyOffersRequest = () => ({ type: GET_MY_COMPANY_OFFERS_REQUEST })
export const getMyCompanyOffersSuccess = (data) => ({ type: GET_MY_COMPANY_OFFERS_SUCCESS, payload: data })
export const getMyCompanyOffersFailure = (error) => ({ type: GET_MY_COMPANY_OFFERS_FAILURE, payload: error })
export const getMyCompanyOfferRequest = () => ({ type: GET_MY_COMPANY_OFFER_REQUEST })
export const getMyCompanyOfferSuccess = (data) => ({ type: GET_MY_COMPANY_OFFER_SUCCESS, payload: data })
export const getMyCompanyOfferFailure = (error) => ({ type: GET_MY_COMPANY_OFFER_FAILURE, payload: error })

export const getCompanyById = (id) => async (dispatch) => {
  try {
    dispatch(getViewCompanyRequest())
    const token = localStorage.getItem('token')
    const result = await CompanyService.getCompanyById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewCompanySuccess(result.company))
    } else {
      dispatch(getViewCompanyFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewCompanyFailure(e.toString()))
  }
}

export const getMyCompanies = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await CompanyService.getMyCompanies(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyCompaniesSuccess(result.companies))
    } else {
      dispatch(getMyCompaniesFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyCompaniesFailure(e.toString()))
  }
}

export const getMyCompanyById = (id) => async (dispatch) => {
  try {
    dispatch(getMyCompanyRequest())
    const token = localStorage.getItem('token')
    const result = await CompanyService.getMyCompanyById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyCompanySuccess(result.company))
    } else {
      dispatch(getMyCompanyFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyCompanyFailure(e.toString()))
  }
}

export const getMyCompanyOffers = (id) => async (dispatch) => {
  try {
    dispatch(getMyCompanyOffersRequest())
    const token = localStorage.getItem('token')
    const result = await CompanyService.getMyCompanyOffers(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyCompanyOffersSuccess(result))
    } else {
      dispatch(getMyCompanyOffersFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyCompanyOffersFailure(e.toString()))
  }
}

export const getMyCompanyOffer = (company_id, offer_id) => async (dispatch) => {
  try {
    dispatch(getMyCompanyOfferRequest())
    const token = localStorage.getItem('token')
    const result = await CompanyService.getMyCompanyOffer(company_id, offer_id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyCompanyOfferSuccess(result))
    } else {
      dispatch(getMyCompanyOfferFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyCompanyOfferFailure(e.toString()))
  }
}

import api from './api'

class ContentService
{
	async getAuthPages() {
		try {
			const response = await api.get('/content/auth-pages')
			return response.data
		} catch (e) {
			console.log('[ContentService][getAuthPages][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getAuthPageBySlug(slug) {
		try {
			const response = await api.get(`/content/auth-page${slug}`)
			return response.data
		} catch (e) {
			console.log('[ContentService][getAuthPageBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getInterests() {
		try {
			const response = await api.get('/content/interests')
			return response.data
		} catch (e) {
			console.log('[ContentService][getInterests][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new ContentService()

import image from '@/assets/images/login-image.jpg'

const ImageRight = () => {
	return (
		<div className="loginForm-right">
			<div className="loginForm-image">
				<img src={image} width="752" height="752" alt="" />
			</div>
		</div>
	)
}

export default ImageRight

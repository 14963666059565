import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetError } from '@/actions/auth'
import { useLocation } from 'react-router-dom'
import EllipseLeft from './EllipseLeft'
import ImageRight from './ImageRight'
import { LoginHeading as Heading } from './Headings'
import loginSchema from '@/validations/login.schema'
import ApiError from './ApiError'
import { LoginError } from './Errors'

const FormFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<>
			{params.map((param, index) => {
				const { name, type, label, value, error } = param
				return (
					<div
						className={`loginForm-field loginForm-field-50${error ? ' form-field-error' : ''}`}
						key={index}
					>
						<input
							type={type}
							name={name}
							placeholder={label}
							value={value}
							autoComplete="new-password"
							required
							onChange={handleInputChange}
						/>
						{error && <span className="form-field-icon">!</span>}
					</div>
				)
			})}
		</>
	)
}

const LoginForm = ({ texts, onSubmit }) => {
	const dispatch = useDispatch()
  	const location = useLocation()

	const apiError = useSelector(state => state.auth.error)

	useEffect(() => {
		dispatch(resetError())
	}, [dispatch, location.pathname])

	const [data, setData] = useState({
		email: '',
		password: ''
	})

    const [errors, setErrors] = useState({
        email: false,
        password: false
    })

	const params = [
		{ name: 'email', type: 'email', label: 'email', value: data.email, error: errors.email },
		{ name: 'password', type: 'password', label: 'hasło', value: data.password, error: errors.password }
	]

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: false })
	}

    const setError = (name) => {
        setErrors({ ...errors, [name]: true })
        return false
    }

    const validateForm = async (formData) => {
      try {
        await loginSchema.validate(formData, { abortEarly: false })
        return true
      } catch (errors) {
      	// console.error(errors.inner)
        let error = errors.inner[0]
        return setError(error.params.path)
      }
    }

	const handleFormSubmit = async () => {
        const formValid = await validateForm(data)
        if (formValid === true) {
			onSubmit(data)
        }
	}

	return (
		<form>
			<div className="loginForm-wrap">
				<EllipseLeft />
				<div className="loginForm-left">
					<Heading title={texts.title} />
					<div className="loginForm-fields loginForm-fields-col">
						<FormFields params={params} onInputChange={onInputChange} />
						<div className="loginForm-field loginForm-field-btn">
							<button type="button" onClick={handleFormSubmit}>ZALOGUJ SIĘ</button>
						</div>
						<div
							className="loginForm-field loginForm-field-login"
							dangerouslySetInnerHTML={{ __html: texts.forgot_password_text }}
						></div>
						{apiError && <ApiError error={<LoginError />} />}
					</div>
				</div>
				<ImageRight />
			</div>
		</form>
	)
}

export default LoginForm

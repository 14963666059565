import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyCompanyOffers } from '@/actions/company'
import { useParams } from 'react-router-dom'
import CompanyOffers from '@/components/Offer/CompanyOffers'

const CompanyOffersPage = () => {
    const { id } = useParams()
	const dispatch = useDispatch()

	const myCompany = useSelector(state => state.company.myCompany)
	const offers = useSelector(state => state.company.offers)

	useEffect(() => {
        if (id) {
        	dispatch(getMyCompanyOffers(id))
        }
	}, [id, dispatch])

	if (!myCompany) return

	return <CompanyOffers company={myCompany} offers={offers} />
}

export default CompanyOffersPage

import {
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_ACTIVITY_TYPES_SUCCESS,
  GET_ACTIVITY_TYPES_FAILURE,
  SEARCH_DATA_REQUEST,
  SEARCH_DATA_SUCCESS,
  SEARCH_DATA_FAILURE,
} from '../actions/search'

const initialState = {
  loading: true,
  cities: [],
  activityTypes: [],
  results: [],
  error: null,
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        error: null
      }
    case GET_CITIES_FAILURE:
      return {
        ...state,
        cities: [],
        error: action.payload
      }
    case GET_ACTIVITY_TYPES_SUCCESS:
      return {
        ...state,
        activityTypes: action.payload,
        error: null
      }
    case GET_ACTIVITY_TYPES_FAILURE:
      return {
        ...state,
        activityTypes: [],
        error: action.payload
      }
    case SEARCH_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEARCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload,
        error: null
      }
    case SEARCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        results: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default searchReducer

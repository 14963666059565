import { useState, useEffect } from 'react'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import SocialLinks from '@/components/Common/Social/SocialLinks'
import imageStatus1 from '@/assets/images/status-compamy-1.svg'
import imageStatus2 from '@/assets/images/status-compamy-2.svg'
import imageStatus3 from '@/assets/images/status-compamy-3.svg'

const BlockDashboardInfo = ({ organization }) => {
	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		name: '',
		website: '',
		description: '',
		activity_type: '',
		specialization: '',
		studies: '',
		address: '',
		members: 0,
	})

	const [socials, setSocials] = useState([])

	useEffect(() => {
		if (organization) {
			const {
				background_image,
				logo_image,
				name,
				website,
				description,
				activity_type,
				specialization,
				studies,
				address,
				members,
			} = organization

			setImages({
				background_image,
				logo_image,
			})

			setData({
				name: name || '',
				website: website || '',
				description: description || '',
				activity_type: activity_type || '',
				specialization: specialization || '',
				studies: studies || '',
				address: address || '',
				members: members.length || 0,
			})

			setSocials(organization.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [organization])

	const getImage = (type) => {
		if (organization && organization[`${type}_image`]) {
			return `${STORAGE_URL}/${organization[`${type}_image`]}` 
		}
		return type === 'logo' ? userDefault : null
	}

	const getParam = (param) => {
		return data[param] || '(brak danych)'
	}

	return (
		<div className="block block-companyDashboard">
			<div className="block-companyDashboard_wrap">
				<div className="block-companyDashboard_left">
					<div className="block-companyDashboard_photo">
						<img src={getImage('logo')} width="" height="" alt="" />
					</div>
					<div className="block-companyDashboard_status">
						<span><img src={imageStatus1} width="29" height="29" alt="" /></span>
						<span><img src={imageStatus2} width="29" height="29" alt="" /></span>
						<span><img src={imageStatus3} width="29" height="29" alt="" /></span>
					</div>
				</div>
				<div className="block-companyDashboard_right">
					<p className="block-companyDashboard_name">{data.name}</p>
					<p className="block-companyDashboard_site"><a href="#"><strong>{data.website}</strong></a></p>

					<div className="block-companyDashboard_info">
						<div className="block-listIcons block-companyDashboard_listIcons">
							<ul>
								<li className="law"><span><strong>{getParam('studies')}</strong></span></li>
								<li className="peoples">
									<span>{data.members && <strong>{data.members} osób</strong>}</span>
								</li>
								<li className="star"><span><strong>250 obserwujących profil</strong></span></li>
							</ul>
						</div>
						<div className="block-companyDashboard_edit">
							<a className="btn btn-md btn-primary">EDYTUJ PROFIL ORGNIZACJI</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BlockDashboardInfo

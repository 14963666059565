import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import BlockEditUser from '@/components/User/BlockEditUser'
import BlockEditAbout from '@/components/User/BlockEditAbout'
import BlockEditInterests from '@/components/User/BlockEditInterests'
import BlockEditWorks from '@/components/User/BlockEditWorks'
import BlockEditProjects from '@/components/User/BlockEditProjects'
import BlockEditAchievements from '@/components/User/BlockEditAchievements'
import BlockEditSkills from '@/components/User/BlockEditSkills'

const UserEditPage = ({ user }) => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
  	const block = params.get('block')
	// console.log(block)

	useEffect(() => {
		if (block) {
			const element = document.getElementById(block)
			if (element) {
				setTimeout(() => window.scroll({ top: element.offsetTop, behavior: 'smooth' }), 500)
			}
		}
	}, [block])

	const onSave = () => {
		
	}

	return (
		<>
			<BlockEditUser user={user} onSave={onSave} />
			<BlockEditAbout user={user} onSave={onSave} />
			<BlockEditInterests user={user} onSave={onSave} />
			<BlockEditWorks user={user} onSave={onSave} />
			<BlockEditProjects user={user} onSave={onSave} />
			<BlockEditAchievements user={user} onSave={onSave} />
			<BlockEditSkills user={user} onSave={onSave} />
		</>
	)
}

export default UserEditPage

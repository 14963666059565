import React, { useEffect, useLayoutEffect, lazy, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { getUser } from './actions/auth'

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import RootPage from './pages/RootPage'

import RegisterPage from './pages/Auth/RegisterPage'
import VerifyEmailPage from './pages/Auth/VerifyEmailPage'
import LoginPage from './pages/Auth/LoginPage'
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage'
import ChangePasswordPage from './pages/Auth/ChangePasswordPage'
import PasswordChangedPage from './pages/Auth/PasswordChangedPage'

import UserProfilePage from './pages/User/UserProfilePage'
import UserDashboardPage from './pages/User/UserDashboardPage'
import UserEditPage from './pages/User/UserEditPage'

import OrganizationProfilePage from './pages/Organization/OrganizationProfilePage'
import MyOrganizationPage from './pages/Organization/MyOrganizationPage'
import OrganizationDashboardPage from './pages/Organization/OrganizationDashboardPage'

import ProjectProfilePage from './pages/Project/ProjectProfilePage'

import CompanyProfilePage from './pages/Company/CompanyProfilePage'

import OffersSearchPage from './pages/Offer/OffersSearchPage'
import CompanyOffersPage from './pages/Offer/CompanyOffersPage'
import OfferCreatePage from './pages/Offer/OfferCreatePage'
import OfferEditPage from './pages/Offer/OfferEditPage'
import OfferPage from './pages/Offer/OfferPage'

import SearchPage from './pages/SearchPage'

import ChatPage from './pages/ChatPage'

import NotFoundPage from './pages/NotFoundPage'

const AuthLayout = lazy(() => import('./layouts/AuthLayout'))
const ProfileLayout = lazy(() => import('./layouts/ProfileLayout'))

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  const Wrapper = ({children}) => {
    const location = useLocation()
    useLayoutEffect(() => document.documentElement.scrollTo(0, 0), [location.pathname])
    return children
  } 

  return (
    <div className="App">
      <Router>
        <Wrapper>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/" element={<RootPage />} />

              <Route path="/register" element={<AuthLayout><RegisterPage headerButton="login" /></AuthLayout>} />
              <Route path="/verify-email" element={<AuthLayout><VerifyEmailPage /></AuthLayout>} />
              <Route path="/login" element={<AuthLayout><LoginPage headerButton="register" /></AuthLayout>} />
              <Route path="/forgot-password" element={<AuthLayout><ForgotPasswordPage headerButton="register" /></AuthLayout>} />
              <Route path="/change-password" element={<AuthLayout><ChangePasswordPage headerButton="register" /></AuthLayout>} />
              <Route path="/password-changed" element={<AuthLayout><PasswordChangedPage headerButton="register" /></AuthLayout>} />

              <Route path="/user/profile/:id" element={<ProfileLayout><UserProfilePage /></ProfileLayout>} />
              <Route path="/user/dashboard" element={<ProfileLayout><UserDashboardPage /></ProfileLayout>} />
              <Route path="/user/edit" element={<ProfileLayout><UserEditPage /></ProfileLayout>} />

              <Route path="/organization/profile/:id" element={<ProfileLayout><OrganizationProfilePage /></ProfileLayout>} />
              <Route
                path="/organization/my"
                element={
                  <ProfileLayout>
                    <MyOrganizationPage                      
                      leftWidgets={['MyOrganizations']}
                      rightWidgets={['MyOrganizations', 'ManageOrganizations', 'PowerBase']}
                    />
                  </ProfileLayout>
                }
              />
              <Route
                path="/organization/dashboard/:id"
                element={
                  <ProfileLayout>
                    <OrganizationDashboardPage                      
                      leftWidgets={['MyOrganizations']}
                      rightWidgets={['MyOrganizations', 'ManageOrganizations', 'PowerBase']}
                    />
                  </ProfileLayout>
                }
              />

              <Route path="/project/profile/:id" element={<ProfileLayout><ProjectProfilePage /></ProfileLayout>} />

              <Route path="/company/profile/:id" element={<ProfileLayout><CompanyProfilePage /></ProfileLayout>} />

              <Route
                path="/offers"
                element={
                  <ProfileLayout>
                    <OffersSearchPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />
              <Route
                path="/offers/company/:id"
                element={
                  <ProfileLayout>
                    <CompanyOffersPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />
              <Route
                path="/offers/company/:id/create"
                element={
                  <ProfileLayout>
                    <OfferCreatePage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />
              <Route
                path="/offers/company/:company_id/edit/:offer_id"
                element={
                  <ProfileLayout>
                    <OfferEditPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />
              <Route
                path="/offer/:id/view"
                element={
                  <ProfileLayout>
                    <OfferPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />

              <Route
                path="/search"
                element={
                  <ProfileLayout>
                    <SearchPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />

              <Route
                path="/chat/:id?"
                element={
                  <ProfileLayout>
                    <ChatPage wrapClass="main-center-right" />
                  </ProfileLayout>
                }
              />

              <Route path="/404" element={<ProfileLayout><NotFoundPage /></ProfileLayout>} />
              <Route path="*" element={<ProfileLayout><NotFoundPage /></ProfileLayout>} />
            </Routes>
          </Suspense>
        </Wrapper>
      </Router>
    </div>
  )
}

export default App

import {
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  GET_DIALOGS_REQUEST,
  GET_DIALOGS_SUCCESS,
  GET_DIALOGS_FAILURE,
  SEARCH_DIALOGS_REQUEST,
  SEARCH_DIALOGS_SUCCESS,
  SEARCH_DIALOGS_FAILURE,
  GET_DIALOG_REQUEST,
  GET_DIALOG_SUCCESS,
  GET_DIALOG_FAILURE,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_INITIAL_MESSAGES_REQUEST,
  GET_INITIAL_MESSAGES_SUCCESS,
  GET_INITIAL_MESSAGES_FAILURE,
  GET_OLDER_MESSAGES_REQUEST,
  GET_OLDER_MESSAGES_SUCCESS,
  GET_OLDER_MESSAGES_FAILURE,
  COUNT_UNREAD_SUCCESS,
  COUNT_UNREAD_FAILURE,
  SET_UNREAD,
} from '../actions/chat'

const initialState = {
  users: [],
  userDialogs: [],
  foundDialogs: [],
  dialog: null,
  dialogMessages: [],
  lastMessages: [],
  olderMessages: [],
  unread: 0,
  error: null,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_USERS_REQUEST:
      return {
        ...state
      }
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null
      }
    case SEARCH_USERS_FAILURE:
      return {
        ...state,
        users: [],
        error: action.payload
      }
    case GET_DIALOGS_REQUEST:
      return {
        ...state
      }
    case GET_DIALOGS_SUCCESS:
      return {
        ...state,
        userDialogs: action.payload,
        error: null
      }
    case GET_DIALOGS_FAILURE:
      return {
        ...state,
        userDialogs: [],
        error: action.payload
      }
    case SEARCH_DIALOGS_REQUEST:
      return {
        ...state
      }
    case SEARCH_DIALOGS_SUCCESS:
      return {
        ...state,
        foundDialogs: action.payload,
        error: null
      }
    case SEARCH_DIALOGS_FAILURE:
      return {
        ...state,
        foundDialogs: [],
        error: action.payload
      }
    case GET_DIALOG_REQUEST:
      return {
        ...state
      }
    case GET_DIALOG_SUCCESS:
      return {
        ...state,
        dialog: action.payload,
        error: null
      }
    case GET_DIALOG_FAILURE:
      return {
        ...state,
        dialog: null,
        error: action.payload
      }
    case GET_MESSAGES_REQUEST:
      return {
        ...state
      }
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        dialogMessages: action.payload,
        error: null
      }
    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        dialogMessages: [],
        error: action.payload
      }
    case GET_INITIAL_MESSAGES_REQUEST:
      return {
        ...state
      }
    case GET_INITIAL_MESSAGES_SUCCESS:
      return {
        ...state,
        lastMessages: action.payload,
        error: null
      }
    case GET_INITIAL_MESSAGES_FAILURE:
      return {
        ...state,
        lastMessages: [],
        error: action.payload
      }
    case GET_OLDER_MESSAGES_REQUEST:
      return {
        ...state
      }
    case GET_OLDER_MESSAGES_SUCCESS:
      return {
        ...state,
        olderMessages: action.payload,
        error: null
      }
    case GET_OLDER_MESSAGES_FAILURE:
      return {
        ...state,
        olderMessages: [],
        error: action.payload
      }
    case COUNT_UNREAD_SUCCESS:
      return {
        ...state,
        unread: action.payload,
        error: null
      }
    case COUNT_UNREAD_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case SET_UNREAD:
      return {
        ...state,
        unread: action.payload
      }
    default:
      return state
  }
}

export default chatReducer

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveAbout } from '@/actions/user'
import TextEditor from '@/components/Common/Edit/TextEditor'
import ConfirmButton from './ConfirmButton'

import './styles.css'

const BlockEditAbout = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const [data, setData] = useState({ about: '' })

	useEffect(() => {
		if (user) {
			setData({ about: user.profile.about })
		}
	}, [user])

	/*const handleTextareaChange = (e) => {
		setData({ about: e.target.value })
	}*/

	const handleContentChange = (content) => {
		setData({ about: content })
	}

	const onSubmit = async () => {
		await dispatch(saveAbout(data))
		onSave()
	}

	return (
		<form className="block block-editProfileAbout" id="about">
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>Kilka słów o sobie</h2>
				</div>
				<div className="form-fields">
					<div className="form-field">
						{/*<textarea
							placeholder="Wpisz treść - max 2000 znaków"
							onChange={handleTextareaChange}
							value={data.about}
						></textarea>*/}
						<TextEditor content={data.about} onContentChange={handleContentChange} />
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</form>
	)
}

export default BlockEditAbout

import { useState, useEffect } from 'react'

const BlockAbout = ({ company }) => {
	const [data, setData] = useState({ about: '' })

	useEffect(() => {
		if (company) {
			setData({ about: company.about })
		}
	}, [company])

	const [open, setOpen] = useState(false)

	const toggleContent = () => {
		setOpen(!open)
	}

	return (
		<div className="block block-about">
			<div className={`block-wrap${open ? ' show-content' : ''}`}>
				<div className="block-heading yellow">
					<h2>o firmie</h2>
				</div>
				<div
					className="block-content block-content-overflow block-lh-2"
					dangerouslySetInnerHTML={{ __html: data.about || '(brak danych)'}}
				></div>
				{data.about && <div className="block-content-more">
					<a
						style={{ cursor: 'pointer' }}
						onClick={toggleContent}
					>
						{!open && <span>rozwiń</span>}
						{open && <span>zwiń</span>}
					</a>
				</div>}
			</div>
		</div>
	)
}

export default BlockAbout

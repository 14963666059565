import { useState } from 'react'
import BlockUser from '@/components/User/BlockUser'
import BlockEditUser from '@/components/User/BlockEditUser'
import BlockAbout from '@/components/User/BlockAbout'
import BlockEditAbout from '@/components/User/BlockEditAbout'
import BlockInterests from '@/components/User/BlockInterests'
import BlockEditInterests from '@/components/User/BlockEditInterests'
import BlockWorks from '@/components/User/BlockWorks'
import BlockEditWorks from '@/components/User/BlockEditWorks'
import BlockProjects from '@/components/User/BlockProjects'
import BlockEditProjects from '@/components/User/BlockEditProjects'
import BlockAchievements from '@/components/User/BlockAchievements'
import BlockEditAchievements from '@/components/User/BlockEditAchievements'
import BlockSkills from '@/components/User/BlockSkills'
import BlockEditSkills from '@/components/User/BlockEditSkills'

const UserDashboardPage = ({ user }) => {
	const [blocks, setBlocks] = useState([
		{ name: 'user', showComponent: BlockUser, editComponent: BlockEditUser, edit: false },
		{ name: 'about', showComponent: BlockAbout, editComponent: BlockEditAbout, edit: false },
		{ name: 'interests', showComponent: BlockInterests, editComponent: BlockEditInterests, edit: false },
		{ name: 'works', showComponent: BlockWorks, editComponent: BlockEditWorks, edit: false },
		{ name: 'projects', showComponent: BlockProjects, editComponent: BlockEditProjects, edit: false },
		{ name: 'achievements', showComponent: BlockAchievements, editComponent: BlockEditAchievements, edit: false },
		{ name: 'skills', showComponent: BlockSkills, editComponent: BlockEditSkills, edit: false },
	])

	const onEdit = (blockName) => {
		setBlocks((prevBlocks) =>
			prevBlocks.map((block) =>
				block.name === blockName ? { ...block, edit: true } : block
			)
		)
	}

	const onSave = (blockName) => {
		setBlocks((prevBlocks) =>
			prevBlocks.map((block) =>
				block.name === blockName ? { ...block, edit: false } : block
			)
		)
	}

	return (
		<>
			{blocks.map((block, index) => {
				const { name, showComponent: ShowComponent, editComponent: EditComponent, edit } = block
				if (edit) return <EditComponent key={index} user={user} onSave={() => onSave(name)} />
				return <ShowComponent key={index} user={user} isOwn={true} onEdit={() => onEdit(name)} />
			})}
		</>
	)
}

export default UserDashboardPage

const NotFoundPage = () => {
	return (
		<div style={{
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
			<h1>404</h1>
			<h2>Strona nie znaleziona</h2>
		</div>
	)
}

export default NotFoundPage

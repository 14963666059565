const ConfirmButton = ({ onSubmit }) => {
	return (		
		<div className="block-editProfile-confirm">
			<button
				type="button"
				className="btn btn-primary btn-icon-right btn-icon-check"
				onClick={onSubmit}
			>
				<span>ZATWIERDŹ</span>
			</button>
		</div>
	)
}

export default ConfirmButton

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const MyOrganizationPage = () => {
	const navigate = useNavigate()

    const myOrganizations = useSelector(state => state.organization.myOrganizations)

    useEffect(() => {
    	if (myOrganizations.length) {
    		navigate(`/organization/dashboard/${myOrganizations[0].id}`)
    	}
    }, [myOrganizations])

    return null
}

export default MyOrganizationPage

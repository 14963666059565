export const escapeParagraphs = (html) => {
	return html.replace(/<p>/g, '').replace(/<\/p>/g, '')
}

export const escapeNonBreakSpaces = (html) => {
	return html.replace(/&nbsp;/g, '')
}

export const escape = (html) => {
	return escapeParagraphs(escapeNonBreakSpaces(html))
}

export const generateRandomString = (length = 16) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }
  
  return result
}

export const ucfirst = (str) => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getCarouselPosition = (item) => {
  const total = item.count
  const index = Math.floor(item.index - total / 2)

  let from = index + 1
  let to = from + 1

  if (from <= 0 && to <= 0) {
    from = 1
    to = 2
  }

  if (to > total) to = total

  if (from == 0) {
    from = total
  }

  if (from == total && to == total) {
    to = 1
  }

  return { from, to, total }
}

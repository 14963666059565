import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveAchievements } from '@/actions/user'
import { generateRandomString } from '@/utils/content.utils'
import circleCheck from '@/assets/images/circle-check-wave.svg'
import ConfirmButton from './ConfirmButton'

import './styles.css'

const BlockEditAchievements = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const initialData = { key: null, content: '' }

	const [achievements, setAchievements] = useState(Array(5).fill(initialData))

	useEffect(() => {
		if (user) {
			const userAchievements = user.achievements.map((achievement) => ({
				key: achievement.key || generateRandomString(),
				content: achievement.content || ''
			}))

			setAchievements([
				...userAchievements,
				...Array(5 - userAchievements.length).fill(initialData)
			])
		}
	}, [user, initialData])

	const handleInputChange = (index, value) => {
		const newAchievements = [...achievements]
	    const achievement = { ...newAchievements[index] }
	    if (!achievement.key) achievement.key = generateRandomString()
	    achievement.content = value
	    newAchievements[index] = achievement
	    setAchievements(newAchievements)
	}

	const onSubmit = async () => {
		await dispatch(saveAchievements({
			achievements: achievements.filter(item => item.key && item.content)
		}))
		onSave()
	}

	return (
		<form className="block block-editProfileAchievements" id="achievements">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>Osiągnięcia</h2>
				</div>
				<p className="block-editProfileAchievements-description">
					Wpisz do 5 najważniejszych osiągnięć
				</p>
				<div className="block-editProfileAchievements-fields">
					<div className="form-fields">
						{achievements.map((achievement, index) => {
							const { content } = achievement
							return (								
								<div className="form-field" key={index}>
									<div className="block-editProfileAchievements-fields-item">
										<span className="block-editProfileAchievements-fields-item_label">
											<img src={circleCheck} width="" height="" alt="" />
										</span>
										<span className="block-editProfileAchievements-fields-item_value">
											<input
												type="text"
												placeholder="wpisz osiągnięcie"
												value={content}
												onChange={(e) => handleInputChange(index, e.target.value)}
											/>
										</span>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</form>
	)
}

export default BlockEditAchievements

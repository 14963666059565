import {
  GET_ACCOUNT_TYPES_SUCCESS,
  GET_ACCOUNT_TYPES_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  CHECK_RECOVER_TOKEN_SUCCESS,
  CHECK_RECOVER_TOKEN_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  LOGOUT,
  RESET_ERROR
} from '../actions/auth'

const initialState = {
  accountTypes: [],
  userLoading: true,
  isAuthenticated: false,
  user: null,
  token: null,
  recoverEmail: null,
  error: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.payload,
        error: null
      }
    case GET_ACCOUNT_TYPES_FAILURE:
      return {
        ...state,
        accountTypes: [],
        error: action.payload
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        error: null
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        error: null
      } 
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: false,
        user: null,
        token: null,
        error: action.payload
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        error: null
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        error: action.payload
      }
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null
      }
    case RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case CHECK_RECOVER_TOKEN_SUCCESS:
      return {
        ...state,
        recoverEmail: action.payload,
        error: null
      } 
    case CHECK_RECOVER_TOKEN_FAILURE:
      return {
        ...state,
        recoverEmail: null,
        error: action.payload
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case GET_USER_REQUEST:
      return {
        ...state,
        userLoading: true
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: true,
        user: action.payload,
        error: null
      }
    case GET_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: false,
        user: null,
        // error: action.payload
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        error: null
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default authReducer

import { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'

const BlockSupport = ({ project }) => {
	const [partners, setPartners] = useState([])
	const [data, setData] = useState({ budget: 0, support: 0 })

	useEffect(() => {
		if (project) {
			setPartners(project.partners)
			setData({
				budget: project.budget || 0,
				support: project.support || 0
			})
		}
	}, [project])

	const responsive = {
        0: {
            margin: 20,
            items: 2
        },
        768: {
            margin: 30,
            items: 3
        },
        992: {
            margin: 30,
            items: 4
        }
    }

	if (!partners.length) {
		return null
	}

	return (
		<div className="block block-support">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>support projektu</h2>
				</div>
				<div className="block-support_advantages">
					<div className="block-support_advantage">
						<p className="block-support_advantage-title">partnerzy</p>
						<p className="block-support_advantage-info">
							<span>{partners.length}</span>podmiotów
						</p>
					</div>
					<div className="block-support_advantage">
						<p className="block-support_advantage-title">budżet projektu</p>
						<p className="block-support_advantage-info">
							<span>{data.budget}</span>PLN
						</p>
					</div>
					<div className="block-support_advantage">
						<p className="block-support_advantage-title">wsparcie projektu</p>
						<p className="block-support_advantage-info">
							<span>{data.support}</span>PLN
						</p>
					</div>
				</div>
				<div className="block-partners-items">
					<OwlCarousel
						className='owl-partners owl-carousel'
						loop
						nav
						dots={false}
						responsive={responsive}
					>
						{partners.map((partner, index) => {
							const { name, logo } = partner
							return (
								<div className="block-partners-item" key={index}>
									<img src={logo} width="" height="" alt={name} />
								</div>
							)
						})}
					</OwlCarousel>
				</div>
			</div>
		</div>
	)
}

export default BlockSupport

import { useState, useEffect } from 'react'
import { formatDate, formatPeriod } from '@/utils/datetime.utils'
import { generateRandomString } from '@/utils/content.utils'
import jobSchema from '@/validations/job.schema'
import DateEditor from '@/components/Common/Edit/DateEditor'
import circleCheck from '@/assets/images/circle-check-field.svg'
import ConfirmButton from './ConfirmButton'

import './styles.css'

const JobItem = ({ params, item, color, btnClass, isOpen, onSave, onSelect }) => {
	const [data, setData] = useState(item)

  const [errors, setErrors] = useState({
    title: false,
    company: false,
    date_from: false,
    date_to: false,
    description: false
  })

	useEffect(() => {
		setData(item)
	}, [item])

	const handleInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
    setErrors({ ...errors, [name]: false })
	}

	const handleFeatureChange = (e, index) => {
		const { value } = e.target
		const features = data.features
		features[index] = value || ''
		setData({ ...data, features })
	}

  const setError = (name) => {
    setErrors({ ...errors, [name]: true })
    return false
  }

  const validateForm = async (formData) => {
    try {
      await jobSchema.validate(formData, { abortEarly: false })
      return true
    } catch (errors) {
    	// console.error(errors.inner)
      let error = errors.inner[0]
      return setError(error.params.path)
    }
  }

	const handleItemSubmit = async () => {
    const formValid = await validateForm(data)
    if (formValid === true) {
    	onSave(data)
    }
	}

	const handleSelectItem = () => {
		onSelect(item.key)
	}

	if (isOpen) return (		
		<div className={`block-editProfileWorks-item block-editProfileWorks-item_style-bg ${color} show`}>
			<div className="block-editProfileWorks-item_body">
				<div className="block-editProfileWorks-fields1">
					<div className="form-fields">
						<div className="form-field">
							<div className="block-editProfileWorks-fields1-item">
								<span className="block-editProfileWorks-fields1-item_label">
									{params.title.label}
								</span>
								<span className={`block-editProfileWorks-fields1-item_value${errors.title ? ' form-field-error' : ''}`}>
									<input
										name="title"
										type="text"
										placeholder={params.title.placeholder}
										value={data.title}
										onChange={(e) => handleInputChange(e.target)}
									/>
									{errors.title && <span className="form-field-icon">!</span>}
								</span>
							</div>
						</div>
						<div className="form-field">
							<div className="block-editProfileWorks-fields1-item">
								<span className="block-editProfileWorks-fields1-item_label">
									{params.company.label}
								</span>
								<span className={`block-editProfileWorks-fields1-item_value${errors.company ? ' form-field-error' : ''}`}>
									<input
										name="company"
										type="text"
										placeholder={params.company.placeholder}
										value={data.company}
										onChange={(e) => handleInputChange(e.target)}
									/>
									{errors.company && <span className="form-field-icon">!</span>}
								</span>
							</div>
						</div>	
						<div className="form-field">
							<div className="block-editProfileWorks-fields1-item">
								<span className="block-editProfileWorks-fields1-item_label">
									{params.date_from.label}
								</span>
								<span className="block-editProfileWorks-fields1-item_value block-editProfileWorks-fields1-item_value-date">
									<DateEditor
										wrapperClassName={errors.date_from ? 'form-field-error' : ''}
										innerHtml={errors.date_from ? <span className="form-field-icon">!</span> : null}
										name="date_from"
										placeholder={params.date_from.placeholder}
										selected={data.date_from}
										onChange={handleInputChange}
									/>
									<span className="block-editProfileWorks-fields1-item_value-sep">-</span>
									<DateEditor
										wrapperClassName={errors.date_to ? 'form-field-error' : ''}
										innerHtml={errors.date_to ? <span className="form-field-icon">!</span> : null}
										name="date_to"
										placeholder={params.date_to.placeholder}
										selected={data.date_to}
										onChange={handleInputChange}
									/>
									<span className="block-editProfileWorks-fields1-item_value-text">
										{params.date_to.label}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="block-editProfileWorks-fields2">
					<div className="form-fields">
						<div className={`form-field${errors.description ? ' form-field-error' : ''}`}>
							<textarea
								name="description"
								placeholder={params.description.placeholder}
								value={data.description}
								onChange={(e) => handleInputChange(e.target)}
							></textarea>
							{errors.description && <span className="form-field-icon">!</span>}
						</div>
					</div>
				</div>
				<div className="block-editProfileWorks-fields3">
					<div className="form-fields">
						{data.features.map((feature, index) => {
							return (
								<div className="form-field" key={index}>
									<div className="block-editProfileWorks-fields3-item">
										<span className="block-editProfileWorks-fields3-item_label">
											<span className="block-editProfileWorks-fields3-item_label-img">
												<img src={circleCheck} width="" height="" alt="" />
											</span>
											{params.features.label}
										</span>
										<span className="block-editProfileWorks-fields3-item_value">
											<textarea
												placeholder={params.features.placeholder}
												value={feature || ''}
												onChange={(e) => handleFeatureChange(e, index)}
											></textarea>
										</span>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="block-editProfile-confirm">
					<button
						type="button"
						className={`btn btn-${btnClass} btn-icon-right btn-icon-check`}
						onClick={handleItemSubmit}
					>
						<span>ZATWIERDŹ WPIS</span>
					</button>
				</div>
			</div>
		</div>
	)

	return (
		<div className={`block-editProfileWorks-item block-editProfileWorks-item_style-bg ${color}`}>
			<div className="block-editProfileWorks-item_head">
				<span className="block-editProfileWorks-item_title">{data.title}</span>
				<span className="block-editProfileWorks-item_company">{data.company}</span>
				<span className="block-editProfileWorks-item_date">{formatPeriod(data.date_from, data.date_to)}</span>
				<span className="block-editProfileWorks-item_edit">
					<a style={{ cursor: 'pointer' }} onClick={handleSelectItem}>edytuj<span></span></a>
				</span>
			</div>
		</div>
	)
}

const JobItems = ({ params, items, color, btnClass, onSave, onSelect }) => {
	return (		
		<div className="block-editProfileWorks-items">
			{items.map((item, index) => 
				<JobItem
					params={params}
					item={item}
					color={color}
					btnClass={btnClass}
					isOpen={item.open}
					key={index}
					onSave={onSave}
					onSelect={onSelect}
				/>
			)}
		</div>
	)
}

const BlockEditJob = ({ params, items, options, onSubmit }) => {
	const { title, anchor, color, icon, btnClass } = options

	const initialData = {
		key: null,
		title: '',
		company: '',
		date_from: null,
		date_to: null,
		description: '',
		features: Array.from({ length: 4 }, v => ''),
		open: true
	}

	const [jobItems, setJobItems] = useState([])

	useEffect(() => {
		setJobItems(items)
	}, [items])

	const handleSaveItem = (item) => {
		setJobItems(prevJobItems => {
      const updatedJobItems = prevJobItems
          .filter(jobItem => jobItem.key !== null)
          .map(jobItem => jobItem.key === item.key ? { ...item, open: false } : jobItem)

      if (item.key === null) {
        updatedJobItems.push({ ...item, key: generateRandomString(), open: false })
      }

      return updatedJobItems
	  })
	}

	const handleSelectItem = (key) => {
		setJobItems(prevJobItems => [
			...prevJobItems.map(item => {
				return { ...item, open: item.key === key }
			})
		])
	}

	const addNewItem = () => {
		setJobItems([
			initialData,
			...jobItems.filter(jobItem => jobItem.key !== null).map(jobItem => ({ ...jobItem, open: false }))
		])
	}

	const handleSubmitItems = () => {
		onSubmit(
			jobItems.filter(jobItem => jobItem.key !== null).map(
				jobItem => ({
					...jobItem,
					date_from: formatDate(jobItem.date_from),
					date_to: formatDate(jobItem.date_to),
				})
			)
		)
	}

	return (
		<form className="block block-editProfileWorks" id={anchor}>
			<div className="block-wrap">
				<div className={`block-heading ${color} block-heading-icon ${icon}`}>
					<h2>{title}</h2>
				</div>
				<JobItems
					params={params}
					items={jobItems}
					color={color}
					btnClass={btnClass}
					onSave={handleSaveItem}
					onSelect={handleSelectItem}
				/>
				<div className="block-editProfile-confirm">
					<button
						type="button"
						className={`btn btn-${btnClass}`}
						onClick={addNewItem}
					>
						<span>DODAJ NOWY WPIS</span>
					</button>
				</div>
				<ConfirmButton onSubmit={handleSubmitItems} />
			</div>
		</form>
	)
}

export default BlockEditJob

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import SocialLinks from '@/components/Common/Social/SocialLinks'

const BlockInfo = ({ project }) => {
	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const emptyOrganization = { name: '', studies: '' }

	const [data, setData] = useState({
		name: '',
		website: '',
		description: '',
		date_from: '',
		date_to: '',
		members: 0,
		organization: emptyOrganization,
	})

	const [socials, setSocials] = useState([])

	useEffect(() => {
		if (project) {
			const {
				background_image,
				logo_image,
				name,
				website,
				description,
				date_from,
				date_to,
				members,
				organization,
			} = project

			setImages({
				background_image,
				logo_image,
			})

			setData({
				name: name || '',
				website: website || '',
				description: description || '',
				date_from: date_from || '',
				date_to: date_to || '',
				members: members.length || 0,
				organization: organization || emptyOrganization,
			})

			setSocials(organization.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [project])

	const getImage = (type) => {
		if (project && project[`${type}_image`]) {
			return `${STORAGE_URL}/${project[`${type}_image`]}` 
		}
		return type === 'logo' ? userDefault : null
	}

	const getParam = (param) => {
		return data[param] || '(brak danych)'
	}

	return (
		<div className="block block-singleProject">
			<div className="block-singleProject_head">
				<div className="block-singleProject_head-left">
					<div className="block-singleProject_head-image-1">
						<img src={getImage('logo')} width="" height="" alt="" />
					</div>
					<p className="block-singleProject_head-url">
						<Link to={data.website}><strong>{data.website}</strong></Link>
					</p>
				</div>
				<div className="block-singleProject_head-right">
					<div className="block-singleProject_head-image-2">
						<img src={getImage('background')} width="" height="" alt="" />
					</div>
				</div>
			</div>
			<p className="block-singleProject_name">{data.name}</p>
			<div className="block-company_desc">
				<p>{data.description}</p>
			</div>
			<div className="block-listIcons">
				<div className="block-listIcons-full">
					<ul>
						<li className="peoples"><span>zespół projektu: <strong>{data.members} osób</strong></span></li>
						<li className="organisation"><span>organizacja projektowa: <strong>{data.organization.name}</strong></span></li>
						<li className="law"><span>w ramach studiów: <strong>{data.organization.studies}</strong></span></li>
					</ul>
				</div>
			</div>
			<div className="block-company_footer">
				<div className="block-company_buttons">
					<div className="block-company_button"><a className="btn btn-primary btn-icon-right btn-icon-star"><span>OBSERWUJ</span></a></div>
					<p className="block-company_button-caption">organizację obserwuje 135 osób</p>
				</div>
				<div className="block-company_soc">
					<SocialLinks socials={socials} />
				</div>
			</div>
		</div>
	)
}

export default BlockInfo

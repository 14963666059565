import * as yup from 'yup'
// import * as C from '../constants/auth'

const forgotPasswordSchema = yup.object().shape({
  email: yup.string()
    .email()
    .required()
})

export default forgotPasswordSchema

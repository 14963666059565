import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveWorks } from '@/actions/user'
import BlockEditJob from './BlockEditJob'

const BlockEditWorks = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const [works, setWorks] = useState([])

	useEffect(() => {
		if (user) {
			setWorks(user.works)
		}
	}, [user])

	const params = {
		title: { label: 'Opis funkcji', placeholder: 'wpisz, np: praktyka w dziale marketingu' },
		company: { label: 'firma/organizacja', placeholder: 'wpisz nazwę' },
		date_from: { label: 'okres', placeholder: 'data start D/M/R' },
		date_to: { label: 'jeśli trwa nie wypełniaj daty stop', placeholder: 'data stop D/M/R' },
		description: { label: null, placeholder: 'Krótki opis obowiązków i osiągnięć - max 400 znaków' },
		features: { label: 'osiągnięcie/obowiązki', placeholder: 'wpisz najważniejsze obowiązki lub rezultaty' }
	}

	const options = {
		title: 'Praca zawodowa',
		anchor: 'works',
		color: 'yellow',
		icon: 'shop',
		btnClass: 'forthy'
	}

	const onSubmit = async (works) => {
		await dispatch(saveWorks({ works }))
		onSave()
	}

	return (
		<BlockEditJob
			params={params}
			items={works}
			options={options}
			onSubmit={onSubmit}
		/>
	)
}

export default BlockEditWorks

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCurrentDate, reverseDateFormat } from '@/utils/datetime.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

import './styles.css'

const ProjectItem = ({ project }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	return (
		<Link to={`/project/profile/${project.id}`} className="block-projects-item short">
			<div className="block-projects-item_image">
				<span><img src={getImage(project.logo_image)} width="" height="" alt="" /></span>
				<span><img src={getImage(project.background_image)} width="" height="" alt="" /></span>
			</div>
			<h3 className="block-projects-item_title">{project.name}</h3>
		</Link>
	)
}

const BlockDashboardProjects = ({ organization }) => {
	const [projects, setProjects] = useState([])
	const [count, setCount] = useState(null)
	const [uncompleted, setUncompleted] = useState(0)

	useEffect(() => {
		if (organization) {
			setProjects(organization.projects)

			const currentDate = getCurrentDate()
			const uncompleted = organization.projects.filter(
				item => item.date_to > currentDate
			).length
			setUncompleted(uncompleted)
			const total = organization.projects.length
			setCount(`${uncompleted} z ${total}`)
		}
	}, [organization])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        576: {
            margin: 10,
            items: 2
        },
        992: {
            margin: 10,
            items: 3
        },
        1280: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 20,
            items: 2
        }
    }

	if (!projects.length) {
		return null
	}

	return (
		<div className="block block-projects">
			<div className="block-wrap">
				<div className="block-heading">
					<h2>Projekty <span>{count}</span></h2>
					<p>masz aktywnych <strong>{uncompleted} projektów</strong></p>
				</div>
				<div className="block-projects-items short">
					<OwlCarousel
						className='owl-projects-short owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
					>
						{projects.map((project, index) => 
							<ProjectItem project={project} key={index} />
						)}						
					</OwlCarousel>
				</div>
				<div className="block-projects-button">
					<a href="#" className="btn btn-primary">ZARZĄDZAJ PROJEKTAMI</a>
				</div>
			</div>
		</div>
	)
}

export default BlockDashboardProjects

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffer } from '@/actions/offer'
import { useParams } from 'react-router-dom'
import OfferBlock from '@/components/Offer/OfferBlock'

const OfferPage = () => {
    const { id } = useParams()
	const dispatch = useDispatch()

	const offer = useSelector(state => state.offer.offer)

	useEffect(() => {
        if (id) dispatch(getOffer(id))
	}, [id, dispatch])

	return (
		<OfferBlock offer={offer} />
	)
}

export default OfferPage

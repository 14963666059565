import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetError } from '@/actions/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import EllipseLeft from './EllipseLeft'
import ImageRight from './ImageRight'
import { PasswordChangedHeading as Heading } from './Headings'

const PasswordChangedForm = ({ texts }) => {
	const dispatch = useDispatch()
  	const location = useLocation()
  	const navigate = useNavigate()

	useEffect(() => {
		dispatch(resetError())
	}, [dispatch, location.pathname])

	const goLogin = () => {
		navigate('/login')
	}

	return (
		<form>
			<div className="loginForm-wrap">				
				<EllipseLeft />
				<div className="loginForm-left">
					<Heading title={texts.title} subtitle={texts.subtitle} />
					<div className="loginForm-fields loginForm-fields-col">
						<div className="loginForm-field loginForm-field-btn">
							<button type="button" onClick={goLogin}>ZALOGUJ SIĘ</button>
						</div>
					</div>
				</div>
				<ImageRight />
			</div>
		</form>
	)
}

export default PasswordChangedForm

import {
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_ACTIVITY_TYPES_SUCCESS,
  GET_ACTIVITY_TYPES_FAILURE,
  SEARCH_OFFERS_REQUEST,
  SEARCH_OFFERS_SUCCESS,
  SEARCH_OFFERS_FAILURE,
  GET_OFFER_REQUEST,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAILURE,
  GET_MY_OFFERS_REQUEST,
  GET_MY_OFFERS_SUCCESS,
  GET_MY_OFFERS_FAILURE,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILURE,
} from '../actions/offer'

const initialState = {
  loading: true,
  cities: [],
  activityTypes: [],
  offers: [],
  offer: null,
  error: null,
}

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        error: null
      }
    case GET_CITIES_FAILURE:
      return {
        ...state,
        cities: [],
        error: action.payload
      }
    case GET_ACTIVITY_TYPES_SUCCESS:
      return {
        ...state,
        activityTypes: action.payload,
        error: null
      }
    case GET_ACTIVITY_TYPES_FAILURE:
      return {
        ...state,
        activityTypes: [],
        error: action.payload
      }
    case SEARCH_OFFERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEARCH_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.payload,
        error: null
      }
    case SEARCH_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        offers: [],
        error: action.payload
      }
    case GET_OFFER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        offer: action.payload,
        error: null
      }
    case GET_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        offer: null,
        error: action.payload
      }
    case GET_MY_OFFERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_MY_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.payload,
        error: null
      }
    case GET_MY_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        offers: [],
        error: action.payload
      }
    case CREATE_OFFER_SUCCESS:
      return {
        ...state,
        error: null
      }
    case CREATE_OFFER_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        error: null
      }
    case UPDATE_OFFER_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default offerReducer

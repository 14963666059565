import {
	Instagram,
	Facebook,
	Youtube,
	Tiktok,
	Linkedin
} from './SocialIcons'

const SocialLinks = ({ socials }) => {
	const platforms = [
		{ platform: 'instagram', icon: <Instagram /> },
		{ platform: 'facebook', icon: <Facebook /> },
		{ platform: 'youtube', icon: <Youtube /> },
		{ platform: 'tiktok', icon: <Tiktok /> },
		{ platform: 'linkedin', icon: <Linkedin /> },
	]

	return (
		<ul className="soc-links">
			{platforms.map((item, index) => {
				const { platform, icon } = item
				const link = socials[platform]
				return link ? <li key={index}><a href={link} target="_blank" rel="noreferrer">{icon}</a></li> : null
			})}
		</ul>
	)
}

export default SocialLinks

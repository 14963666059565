import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getUserById } from '@/actions/user'
import BlockUser from '@/components/User/BlockUser'
import BlockAbout from '@/components/User/BlockAbout'
import BlockInterests from '@/components/User/BlockInterests'
import BlockWorks from '@/components/User/BlockWorks'
import BlockProjects from '@/components/User/BlockProjects'
import BlockAchievements from '@/components/User/BlockAchievements'
import BlockSkills from '@/components/User/BlockSkills'

const UserProfilePage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const viewUserLoading = useSelector(state => state.user.viewUserLoading)
    const viewUser = useSelector(state => state.user.viewUser)

    useEffect(() => {
        if (id) dispatch(getUserById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!viewUser && !viewUserLoading) {
        	navigate('/404')
        }
    }, [viewUserLoading, viewUser, navigate])

	const onEdit = () => {
		
	}

	return (
		<>
			<BlockUser user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockAbout user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockInterests user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockWorks user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockProjects user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockAchievements user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockSkills user={viewUser} isOwn={false} onEdit={onEdit} />
		</>
	)
}

export default UserProfilePage

import * as yup from 'yup'

const jobSchema = yup.object().shape({
  title: yup.string()
    .required(),
  company: yup.string()
    .required(),
  date_from: yup.date()
    .required(),
  date_to: yup.date()
    .nullable()
    .test('is-greater', function(value) {
      const { date_from } = this.parent
      return !value || new Date(value) > new Date(date_from)
    }),
  description: yup.string()
    .required(),    
})

export default jobSchema

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCurrentDate, reverseDateFormat } from '@/utils/datetime.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

import './styles.css'

const ProjectItem = ({ project, area }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<div className="block-projects-item">
			<div className="block-projects-item_image">
				<span><img src={getImage(project.logo_image)} width="" height="" alt="" /></span>
				<div className="block-projects-item_area">
					<p>obszar projektu: <br /><strong>{area}</strong></p>
				</div>
			</div>
			<div className="block-projects-item_info">
				<h3 className="block-projects-item_title">{project.name}</h3>
				<div className="block-projects-item_content">
					<p className={`block-projects-item_description ${isExpanded ? 'expanded' : 'collapsed'}`}>
						{project.description}
						
					</p>
					{!isExpanded && <a onClick={toggleExpand} style={{ cursor: 'pointer' }}>czytaj dalej</a>}
					{isExpanded && <a onClick={toggleExpand} style={{ cursor: 'pointer' }}>zwiń</a>}
				</div>
				<p className="block-projects-item_date">
					{reverseDateFormat(project.date_from)} - {reverseDateFormat(project.date_to)}
				</p>
				<p className="block-projects-item_caption blue">
					Tekst o tym co chcemy podkreślić - hasłowo jedna makimum 2 linijki
				</p>
				<div className="block-projects-item_button">
					<Link
						to={`/project/profile/${project.id}`}
						className="btn btn-sm btn-secondary"
					>OBEJRZYJ PROJEKT</Link>
				</div>
			</div>
		</div>
	)
}

const BlockProjects = ({ organization }) => {
	const [projects, setProjects] = useState([])
	const [data, setData] = useState({ area: '' })
	const [count, setCount] = useState(null)

	useEffect(() => {
		if (organization) {
			setProjects(organization.projects)
			setData({ area: organization.activity_type })

			const currentDate = getCurrentDate()
			const uncompleted = organization.projects.filter(
				item => item.date_to > currentDate
			).length
			const total = organization.projects.length
			setCount(`${uncompleted} z ${total} do dokonczenia`)
		}
	}, [organization])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        992: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 46,
            items: 2
        }
    }

	if (!projects.length) {
		return null
	}

	return (
		<div className="block block-projects">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>projekty organizacji <span>{count}</span></h2>
				</div>
				<div className="block-projects-items">
					<OwlCarousel
						className='owl-projects owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
					>
						{projects.map((project, index) => 
							<ProjectItem project={project} area={data.area} key={index} />
						)}
					</OwlCarousel>
				</div>
				<div className="block-projects-button">
					<a className="btn btn-primary">zobacz wszystkie</a>
				</div>
			</div>
		</div>
	)
}

export default BlockProjects

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetError } from '@/actions/auth'
import { useLocation } from 'react-router-dom'
import EllipseLeft from './EllipseLeft'
import ImageRight from './ImageRight'
import { RegisterHeading as Heading } from './Headings'
import registerSchema from '@/validations/register.schema'
import ApiError from './ApiError'
import { RegisterError } from './Errors'

const PolicyLabel = ({ policyLabel }) => 
	<span dangerouslySetInnerHTML={{ __html: policyLabel }}></span>

const FormFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

    const handleCheckboxChange = (e) => {
    	const { name, checked } = e.target
        onInputChange({ name, value: checked })
    }

	return (
		<>
			{params.map((param, index) => {
				const { name, type, label, value, error } = param
				if (type !== 'checkbox') {
					return (
						<div
							className={`loginForm-field loginForm-field-50${error ? ' form-field-error' : ''}`}
							key={index}
						>
							<input
								type={type}
								name={name}
								placeholder={label}
								value={value}
								autoComplete="new-password"
								required
								onChange={handleInputChange}
							/>
							{error && <span className="form-field-icon">!</span>}
						</div>
					)
				} else {
					const style = error ? { borderColor: '#C0202E'} : {}
					return (
						<div className={`loginForm-field loginForm-field-${name}`} key={index}>
							<label>
								<input
									style={style}
									type={type}
									name={name}
									checked={value}
									required
									onChange={handleCheckboxChange}
								/>
								{label}
							</label>
						</div>
					)
				}
			})}
		</>
	)
}

const RegisterForm = ({ texts, onSubmit }) => {
	const dispatch = useDispatch()
  	const location = useLocation()

	const apiError = useSelector(state => state.auth.error)

	useEffect(() => {
		dispatch(resetError())
	}, [dispatch, location.pathname])

	const [data, setData] = useState({
		firstname: '',
		secondname: '',
		lastname: '',
		email: '',
		password: '',
		password_confirmation: '',
		policy: false
	})

    const [errors, setErrors] = useState({
        firstname: false,
        secondname: false,
        lastname: false,
        email: false,
        password: false,
        password_confirmation: false,
        policy: false
    })

	const params = [
		{ name: 'firstname', type: 'text', label: 'Twoje imię', value: data.firstname, error: errors.firstname },
		{ name: 'secondname', type: 'text', label: 'Drugie imię', value: data.secondname, error: errors.secondname },
		{ name: 'lastname', type: 'text', label: 'Twoje nazwisko', value: data.lastname, error: errors.lastname },
		{ name: 'email', type: 'email', label: 'Twój email', value: data.email, error: errors.email },
		{ name: 'password', type: 'password', label: 'Hasło', value: data.password, error: errors.password },
		{ name: 'password_confirmation', type: 'password', label: 'Powtórz hasło', value: data.password_confirmation, error: errors.password_confirmation },
		{ name: 'policy', type: 'checkbox', label: <PolicyLabel policyLabel={texts.policy_label} />, value: data.policy, error: errors.policy },
	]

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: false })
	}

    const setError = (name) => {
        setErrors({ ...errors, [name]: true })
        return false
    }

    const validateForm = async (formData) => {
      try {
        await registerSchema.validate(formData, { abortEarly: false })
        return true
      } catch (errors) {
      	// console.error(errors.inner)
        let error = errors.inner[0]
        return setError(error.params.path)
      }
    }

  	const [mailSent, setMailSent] = useState(false)

	const handleFormSubmit = async () => {
        const formValid = await validateForm(data)
        if (formValid === true) {
			onSubmit(data)
			setMailSent(true)
        }
	}

	return (
		<form>
			<div className="loginForm-wrap">
				<EllipseLeft />
				<div className="loginForm-left">
					<Heading title={texts.title} subtitle={texts.subtitle} />
					<div className="loginForm-fields">
						{!mailSent && <FormFields params={params} onInputChange={onInputChange} />}
						{mailSent && <div className="loginForm-field restoreForm-field-text">
							{texts.after_register_text}
						</div>}
						{apiError && <ApiError error={<RegisterError />} />}
					</div>
				</div>
				<ImageRight />
				<div className="loginForm-bottom">
					<div className="loginForm-fields">
						{!mailSent && <div className="loginForm-field loginForm-field-btn">
							<button type="button" onClick={handleFormSubmit}>ZAŁÓŻ KONTO</button>
						</div>}
						<div
							className="loginForm-field loginForm-field-login"
							dangerouslySetInnerHTML={{ __html: texts.login_link }}
						></div>
					</div>
				</div>
			</div>
		</form>
	)
}

export default RegisterForm

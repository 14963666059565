import { useDispatch } from 'react-redux'
import { findDialog } from '@/actions/chat'
import { Link, useNavigate } from 'react-router-dom'
import { offerTypes } from '@/constants/offer'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import computers from '@/assets/images/offers/computers.svg'
import location from '@/assets/images/offers/location.svg'
import target from '@/assets/images/offers/target.svg'
import bag from '@/assets/images/offers/bag.svg'
import money from '@/assets/images/offers/money.svg'
import mail from '@/assets/images/mail.svg'

import './styles.css'

const OfferBlock = ({ offer }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

	const goChat = async (collocutor_id) => {
		const dialogId = await dispatch(findDialog({ collocutor_id }))
		if (dialogId) {
			navigate(`/chat/${dialogId}`)
		}
	}

    const goBack = () => {
        navigate(-1)
    }

	if (!offer) return

	return (
		<div className="block block-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-offer-types">
					{Object.entries(offerTypes).map(([key, name]) => (
						<span
							className={`${name === offer.offer_type ? 'active' : ''}`}
							key={key}
						>{name}</span>
					))}
				</div>
				<div className="block-offer-delimiter"></div>
				<div className="block-offer-top">
					<div className="block-offer-top-left">
						<div className="header-icon star block-offer-favorite"><a></a></div>
						<div className="block-offer-title">{offer.title}</div>
						<div className="block-offer-company">{offer.company.name}</div>
						<div className="block-offer-features">
							<div className="block-offer-features-item">
								<img src={computers} alt="" />
								<span>{offer.work_system}</span>
							</div>
							<div className="block-offer-features-item">
								<img src={location} alt="" />
								<span>{offer.city}</span>
							</div>
							<div className="block-offer-features-item">
								<img src={target} alt="" />
								{offer.specializations.length !== 0 && <span>{offer.specializations[0]}</span>}
							</div>
							<div className="block-offer-features-item">
								<img src={bag} alt="" />
								<span>{offer.contract_type}</span>
							</div>
							<div className="block-offer-features-item block-offer-features-item-money">
								<img src={money} alt="" />
								<span>{offer.salary_from} – {offer.salary_to} netto B2B</span>
							</div>
						</div>
					</div>
					<div className="block-offer-top-right">
						<div className="block-offer-logo">
							<img src={getImage(offer.company.logo_image)} alt="" />
						</div>
						<div className="block-offer-ref">
							<div className="block-offer-ref-caption">osoba odpowiedzialna</div>
							<div className="block-offer-ref-user">
								<div className="block-offer-ref-user-photo">
									<div>
										<img src={getImage(offer.ref_user.profile.avatar_image)} alt="" />
									</div>
								</div>
								<div className="block-offer-ref-user-data">
									<div className="block-offer-ref-user-data-name">
										{offer.ref_user.profile.firstname} {offer.ref_user.profile.lastname}
									</div>
									<div className="block-offer-ref-user-data-position">
										{offer.ref_user.profile.position}
									</div>
									<a
										className="block-offer-ref-user-data-chat"
										onClick={() => goChat(offer.ref_user.id)}
									>
										<img src={mail} alt="" />
									</a>
								</div>

							</div>							
						</div>
					</div>					
				</div>
				<div
					className="block-offer-body"
					dangerouslySetInnerHTML={{ __html: offer.content }}
				></div>
				<div className="block-offer-bottom">
					<div className="block-offer-buttons">
						<button className="btn btn-primary">APLIKUJ</button>
					</div>
					<div className="block-offer-buttons">
						<button className="btn btn-outline-primary">OBSERWUJ</button>
						<Link to="/offers" className="btn btn-outline-primary">POWRÓT DO LISTY</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OfferBlock

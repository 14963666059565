import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '@/actions/auth'

const VerifyEmailPage = () => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
  	const token = params.get('token')

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const userLoading = useSelector(state => state.auth.userLoading)
	const user = useSelector(state => state.auth.user)

	useEffect(() => {
		(async () => {
			if (!user && !userLoading) {
				const success = await dispatch(verifyEmail({ token }))
				if (success) navigate('/user/edit')
			}
		})()
	}, [user, userLoading, dispatch, token, navigate])

  	return null
}

export default VerifyEmailPage

import * as yup from 'yup'
// import * as C from '../constants/auth'

const loginSchema = yup.object().shape({
  email: yup.string()
    .email()
    .required(),
  password: yup.string()
    .required(),
})

export default loginSchema

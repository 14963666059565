import {
  GET_VIEW_COMPANY_REQUEST,
  GET_VIEW_COMPANY_SUCCESS,
  GET_VIEW_COMPANY_FAILURE,
  GET_MY_COMPANIES_SUCCESS,
  GET_MY_COMPANIES_FAILURE,
  GET_MY_COMPANY_REQUEST,
  GET_MY_COMPANY_SUCCESS,
  GET_MY_COMPANY_FAILURE,
  GET_MY_COMPANY_OFFERS_REQUEST,
  GET_MY_COMPANY_OFFERS_SUCCESS,
  GET_MY_COMPANY_OFFERS_FAILURE,
  GET_MY_COMPANY_OFFER_REQUEST,
  GET_MY_COMPANY_OFFER_SUCCESS,
  GET_MY_COMPANY_OFFER_FAILURE,
} from '../actions/company'

const initialState = {
  viewCompanyLoading: true,
  viewCompany: null,
  myCompanies: [],
  myCompanyLoading: true,
  myCompany: null,
  offers: [],
  offer: null,
  error: null,
}

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIEW_COMPANY_REQUEST:
      return {
        ...state,
        viewCompanyLoading: true
      }
    case GET_VIEW_COMPANY_SUCCESS:
      return {
        ...state,
        viewCompanyLoading: false,
        viewCompany: action.payload,
        error: null
      }
    case GET_VIEW_COMPANY_FAILURE:
      return {
        ...state,
        viewCompanyLoading: false,
        viewCompany: null,
        error: action.payload
      }
    case GET_MY_COMPANIES_SUCCESS:
      return {
        ...state,
        myCompanies: action.payload,
        error: null
      }
    case GET_MY_COMPANIES_FAILURE:
      return {
        ...state,
        myCompanies: [],
        error: action.payload
      }
    case GET_MY_COMPANY_REQUEST:
      return {
        ...state,
        myCompanyLoading: true
      }
    case GET_MY_COMPANY_SUCCESS:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: action.payload,
        error: null
      }
    case GET_MY_COMPANY_FAILURE:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: null,
        error: action.payload
      }
    case GET_MY_COMPANY_OFFERS_REQUEST:
      return {
        ...state,
        myCompanyLoading: true
      }
    case GET_MY_COMPANY_OFFERS_SUCCESS:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: action.payload.company,
        offers: action.payload.offers,
        error: null
      }
    case GET_MY_COMPANY_OFFERS_FAILURE:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: null,
        offers: [],
        error: action.payload
      }
    case GET_MY_COMPANY_OFFER_REQUEST:
      return {
        ...state,
        myCompanyLoading: true
      }
    case GET_MY_COMPANY_OFFER_SUCCESS:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: action.payload.company,
        offer: action.payload.offer,
        error: null
      }
    case GET_MY_COMPANY_OFFER_FAILURE:
      return {
        ...state,
        myCompanyLoading: false,
        myCompany: null,
        offer: null,
        error: action.payload
      }
    default:
      return state
  }
}

export default organizationReducer

import { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './styles.css'

const modules = {
	toolbar: [
		// [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
		// [{size: []}],
		['bold', 'italic', 'underline'],
		[{'list': 'ordered'}, {'list': 'bullet'}],
		// ['link', 'blockquote', 'code-block'],
		['clean'],
	],
}

const formats = [
	'header', 'font', 'size',
	'bold', 'italic', 'underline',
	'list', 'bullet',
	'link', 'blockquote', 'code-block',
]

const TextEditor = ({ content, onContentChange }) => {
	const [value, setValue] = useState('')
	const quillRef = useRef(null)

	useEffect(() => {
		if (content) {
			setValue(content)
		}
	}, [content])

	const handleChange = (value) => {
		setValue(value)
		onContentChange(value)
	}

	return (
		<ReactQuill
			ref={quillRef}
			value={value}
			onChange={handleChange}
			modules={modules}
			formats={formats}
		/>
	)
}

export default TextEditor

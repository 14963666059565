import { forwardRef } from 'react'
import { formatChatDatetime } from '@/utils/datetime.utils'

const ChatMessage = forwardRef(({ message }, ref) => {
	const { id, is_own, content, sender, datetime, read } = message

	return (
		<div
			ref={ref}
			className={`block-chat-dialog-message ${is_own ? 'own' : ''}`}
			data-id={id}
			data-read={Number(is_own) || read}
		>
			<div className="block-chat-dialog-message-content">{content}</div>
			<div className="block-chat-dialog-message-bottom">
				<div className="block-chat-dialog-message-sender">
					od <span>{is_own ? 'TY' : sender}</span>
				</div>
				<div className="block-chat-dialog-message-datetime">
					{formatChatDatetime(datetime)}
				</div>				
			</div>

		</div>
	)
})

export default ChatMessage

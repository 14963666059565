import { useState, useEffect } from 'react'

import './styles.css'

const BlockAbout = ({ user, isOwn, onEdit }) => {
	const [data, setData] = useState({ about: '' })

	useEffect(() => {
		if (user) {
			setData({ about: user.profile.about })
		}
	}, [user])

	const [open, setOpen] = useState(false)

	const toggleContent = () => {
		setOpen(!open)
	}

	return (
		<div className="block block-about">
			{isOwn && <div className="block-edit">
				{/*<Link to="/user/edit?block=about">edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			<div className={`block-wrap${open ? ' show-content' : ''}`}>
				<div className="block-heading yellow">
					<h2>Kilka słów o sobie</h2>
				</div>
				<div
					className="block-content block-content-overflow block-lh-2"
					dangerouslySetInnerHTML={{ __html: data.about || '(brak danych)'}}
				></div>
				{data.about && <div className="block-content-more">
					<a
						style={{ cursor: 'pointer' }}
						onClick={toggleContent}
					>
						{!open && <span>rozwiń</span>}
						{open && <span>zwiń</span>}
					</a>
				</div>}
			</div>
		</div>
	)
}

export default BlockAbout

import api from './api'

class ChatService
{
	async getDialogs(payload, token) {
		try {
			const response = await api.get(`/chat/dialogs`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][getDialogs][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async searchDialogs(payload, token) {
		try {
			const response = await api.post(`/chat/dialogs/search`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][searchDialogs][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async findDialog(payload, token) {
		try {
			const response = await api.post(`/chat/dialog/find`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][findDialog][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getDialog(id, token) {
		try {
			const response = await api.get(`/chat/dialog/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][getDialog][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getMessages(payload, token) {
		try {
			const response = await api.post(`/chat/messages`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][getMessages][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getInitialMessages(payload, token) {
		try {
			const response = await api.post(`/chat/messages/init`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][getInitialMessages][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getOlderMessages(payload, token) {
		try {
			const response = await api.post(`/chat/messages/older`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][getOlderMessages][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async countUnread(token) {
		try {
			const response = await api.get(`/chat/unread`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][countUnread][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async sendMessage(payload, token) {
		try {
			const response = await api.post(`/chat/message/send`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][sendMessage][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async readMessage(payload, token) {
		try {
			const response = await api.post(`/chat/message/read`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ChatService][readMessage][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new ChatService()

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveSkills, deleteSkill } from '@/actions/user'
import { generateRandomString } from '@/utils/content.utils'
import ConfirmButton from './ConfirmButton'

import './styles.css'

const SkillItem = ({ item, onChange, onRemove }) => {
	const [data, setData] = useState(item)

	useEffect(() => setData(item), [item])

	const handleInputChange = (content) => {
		setData({ ...data, content })
		onChange(content)
	}

	const handleRemoveSkill = () => {
		onRemove()
	}

	return (		
		<div className="form-field">
			<div className="block-editProfileSkills-fields-item">
				<span className="block-editProfileSkills-fields-item_value">
					{data.editable 
						? <span className="form-field-minus">
							<input
								type="text"
								placeholder={data.content}
								value={data.content}
								onChange={(e) => handleInputChange(e.target.value)}
							/>
							<span className="button" onClick={handleRemoveSkill}>-</span>
						</span>
						: <span className="block-editProfileSkills-fields-item_value-title form-field-minus">
							{data.content}
							<span className="button" onClick={handleRemoveSkill}>-</span>
						</span>
					}
				</span>
				<span className="block-editProfileSkills-fields-item_text">
					potwierdziło osób <span>0</span>
				</span>
			</div>
		</div>
	)
}

const AddNewSkill = ({ onAdd }) => {
	const [content, setContent] = useState('')

	const handleInputChange = (e) => {
		setContent(e.target.value)
	}

	const handleAddSkill = () => {
		if (content) {
			onAdd(content)
			setContent('')
		}
	}

	return (
		<div className="block-editProfileSkills-fields1">
			<div className="form-fields">
				<div className="form-field">
					<span className="form-field-plus">
						<input
							type="text"
							placeholder="wpisz nową umiejętność"
							value={content}
							onChange={handleInputChange}
						/>
						<span className="button" onClick={handleAddSkill}>+</span>
					</span>
				</div>
			</div>
		</div>
	)
}

const BlockEditSkills = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const [skills, setSkills] = useState([])

	useEffect(() => {
		if (user) {
			setSkills(user.skills)
		}
	}, [user])

	const handleAddSkill = (content) => {
		setSkills(prevSkills => [ ...prevSkills, {
			content,
			key: null,
			editable: true
		}])
	}

	const handleChangeSkill = (index, content) => {
		const newSkills = [...skills]
	    const skill = { ...newSkills[index] }
	    skill.content = content
	    newSkills[index] = skill
	    setSkills(newSkills)
	}

	const handleRemoveSkill = (index) => {
		const skill = skills[index]
		if (skill.key) dispatch(deleteSkill({ key: skill.key }))
		setSkills([ ...skills.filter((item, i) => i !== index) ])
	}

	const onSubmit = async () => {
		await dispatch(saveSkills({
			skills: skills.map(
				item => !item.key  ? { ...item, key: generateRandomString() } : item
			)
		}))
		onSave()
	}

	return (
		<form className="block block-editProfileSkills" id="skills">
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>Umiejętności</h2>
				</div>
				<p className="block-editProfileSkills-description">
					Wpisz lub edytuj umiejętności. Pamiętaj możesz edytować tylko te umiejętności, których jeszcze nikt nie potwierdził. MOżesz za to każdą umiejętność usunąć.
				</p>
				<AddNewSkill onAdd={handleAddSkill} />
				<div className="block-editProfile-line"></div>
				<div className="block-editProfileSkills-fields2">
					<div className="form-fields">
						{skills.map((item, index) => 
							<SkillItem
								item={item}
								key={index}
								onChange={(content) => handleChangeSkill(index, content)}
								onRemove={() => handleRemoveSkill(index)}
							/>
						)}
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</form>
	)
}

export default BlockEditSkills

import api from './api'

class SearchService
{
	async getCities(token) {
		try {
			const response = await api.get('/search/cities', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[SearchService][getCities][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getActivityTypes(token) {
		try {
			const response = await api.get('/search/activity-types', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[SearchService][getActivityTypes][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async searchData(payload, token) {
		try {
			const response = await api.post('/search/data', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[SearchService][searchData][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new SearchService()

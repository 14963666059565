import UserService from '@/services/user.service'
import ChatService from '@/services/chat.service'

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE'
export const GET_DIALOGS_REQUEST = 'GET_DIALOGS_REQUEST'
export const GET_DIALOGS_SUCCESS = 'GET_DIALOGS_SUCCESS'
export const GET_DIALOGS_FAILURE = 'GET_DIALOGS_FAILURE'
export const SEARCH_DIALOGS_REQUEST = 'SEARCH_DIALOGS_REQUEST'
export const SEARCH_DIALOGS_SUCCESS = 'SEARCH_DIALOGS_SUCCESS'
export const SEARCH_DIALOGS_FAILURE = 'SEARCH_DIALOGS_FAILURE'
export const GET_DIALOG_REQUEST = 'GET_DIALOG_REQUEST'
export const GET_DIALOG_SUCCESS = 'GET_DIALOG_SUCCESS'
export const GET_DIALOG_FAILURE = 'GET_DIALOG_FAILURE'
export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'
export const GET_INITIAL_MESSAGES_REQUEST = 'GET_INITIAL_MESSAGES_REQUEST'
export const GET_INITIAL_MESSAGES_SUCCESS = 'GET_INITIAL_MESSAGES_SUCCESS'
export const GET_INITIAL_MESSAGES_FAILURE = 'GET_INITIAL_MESSAGES_FAILURE'
export const GET_OLDER_MESSAGES_REQUEST = 'GET_OLDER_MESSAGES_REQUEST'
export const GET_OLDER_MESSAGES_SUCCESS = 'GET_OLDER_MESSAGES_SUCCESS'
export const GET_OLDER_MESSAGES_FAILURE = 'GET_OLDER_MESSAGES_FAILURE'
export const COUNT_UNREAD_SUCCESS = 'COUNT_UNREAD_SUCCESS'
export const COUNT_UNREAD_FAILURE = 'COUNT_UNREAD_FAILURE'
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
export const SET_UNREAD = 'SET_UNREAD'

export const searchUsersRequest = () => ({ type: SEARCH_USERS_REQUEST })
export const searchUsersSuccess = (data) => ({ type: SEARCH_USERS_SUCCESS, payload: data })
export const searchUsersFailure = (error) => ({ type: SEARCH_USERS_FAILURE, payload: error })
export const getDialogsRequest = () => ({ type: GET_DIALOGS_REQUEST })
export const getDialogsSuccess = (data) => ({ type: GET_DIALOGS_SUCCESS, payload: data })
export const getDialogsFailure = (error) => ({ type: GET_DIALOGS_FAILURE, payload: error })
export const searchDialogsRequest = () => ({ type: SEARCH_DIALOGS_REQUEST })
export const searchDialogsSuccess = (data) => ({ type: SEARCH_DIALOGS_SUCCESS, payload: data })
export const searchDialogsFailure = (error) => ({ type: SEARCH_DIALOGS_FAILURE, payload: error })
export const getDialogRequest = () => ({ type: GET_DIALOG_REQUEST })
export const getDialogSuccess = (data) => ({ type: GET_DIALOG_SUCCESS, payload: data })
export const getDialogFailure = (error) => ({ type: GET_DIALOG_FAILURE, payload: error })
export const getMessagesRequest = () => ({ type: GET_MESSAGES_REQUEST })
export const getMessagesSuccess = (data) => ({ type: GET_MESSAGES_SUCCESS, payload: data })
export const getMessagesFailure = (error) => ({ type: GET_MESSAGES_FAILURE, payload: error })
export const getInitialMessagesRequest = () => ({ type: GET_INITIAL_MESSAGES_REQUEST })
export const getInitialMessagesSuccess = (data) => ({ type: GET_INITIAL_MESSAGES_SUCCESS, payload: data })
export const getInitialMessagesFailure = (error) => ({ type: GET_INITIAL_MESSAGES_FAILURE, payload: error })
export const getOlderMessagesRequest = () => ({ type: GET_OLDER_MESSAGES_REQUEST })
export const getOlderMessagesSuccess = (data) => ({ type: GET_OLDER_MESSAGES_SUCCESS, payload: data })
export const getOlderMessagesFailure = (error) => ({ type: GET_OLDER_MESSAGES_FAILURE, payload: error })
export const countUnreadSuccess = (data) => ({ type: COUNT_UNREAD_SUCCESS, payload: data })
export const countUnreadFailure = (error) => ({ type: COUNT_UNREAD_FAILURE, payload: error })
export const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST })
export const sendMessageSuccess = (data) => ({ type: SEND_MESSAGE_SUCCESS, payload: data })
export const sendMessageFailure = (error) => ({ type: SEND_MESSAGE_FAILURE, payload: error })
export const setUnread = (data) => ({ type: SET_UNREAD, payload: data })

export const searchUsers = (payload) => async (dispatch) => {
  try {
    dispatch(searchUsersRequest())
    const token = localStorage.getItem('token')
    const result = await UserService.searchUsers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(searchUsersSuccess(result.users))
    } else {
      dispatch(searchUsersFailure(result.error))
    }
  } catch (e) {
    dispatch(searchUsersFailure(e.toString()))
  }
}

export const getDialogs = (payload) => async (dispatch) => {
  try {
    dispatch(getDialogsRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.getDialogs(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(getDialogsSuccess(result.dialogs))
    } else {
      dispatch(getDialogsFailure(result.error))
    }
  } catch (e) {
    dispatch(getDialogsFailure(e.toString()))
  }
}

export const searchDialogs = (payload) => async (dispatch) => {
  try {
    dispatch(searchDialogsRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.searchDialogs(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(searchDialogsSuccess(result.dialogs))
    } else {
      dispatch(searchDialogsFailure(result.error))
    }
  } catch (e) {
    dispatch(searchDialogsFailure(e.toString()))
  }
}

export const findDialog = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ChatService.findDialog(payload, token)
    console.log(result)
    if (result.success) {
      return result.dialog_id
    }
    return null
  } catch (e) {
    console.error(e.toString())
    return null
  }
}

export const getDialog = (id) => async (dispatch) => {
  try {
    dispatch(getDialogRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.getDialog(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getDialogSuccess(result.dialog))
    } else {
      dispatch(getDialogFailure(result.error))
    }
  } catch (e) {
    dispatch(getDialogFailure(e.toString()))
  }
}

export const getMessages = (payload) => async (dispatch) => {
  try {
    dispatch(getMessagesRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.getMessages(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(getMessagesSuccess(result.messages))
    } else {
      dispatch(getMessagesFailure(result.error))
    }
  } catch (e) {
    dispatch(getMessagesFailure(e.toString()))
  }
}

export const getInitialMessages = (payload) => async (dispatch) => {
  try {
    dispatch(getInitialMessagesRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.getInitialMessages(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(getInitialMessagesSuccess(result.messages))
    } else {
      dispatch(getInitialMessagesFailure(result.error))
    }
  } catch (e) {
    dispatch(getInitialMessagesFailure(e.toString()))
  }
}

export const getOlderMessages = (payload) => async (dispatch) => {
  try {
    dispatch(getOlderMessagesRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.getOlderMessages(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(getOlderMessagesSuccess(result.messages))
      return result.messages
    } else {
      dispatch(getOlderMessagesFailure(result.error))
      return []
    }
  } catch (e) {
    dispatch(getOlderMessagesFailure(e.toString()))
    return []
  }
}

export const countUnread = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ChatService.countUnread(token)
    console.log(result)
    if (result.success) {
      dispatch(countUnreadSuccess(result.unread))
    } else {
      dispatch(countUnreadFailure(result.error))
    }
  } catch (e) {
    dispatch(countUnreadFailure(e.toString()))
  }
}

export const sendMessage = (payload) => async (dispatch) => {
  try {
    dispatch(sendMessageRequest())
    const token = localStorage.getItem('token')
    const result = await ChatService.sendMessage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(sendMessageSuccess(result.message))
      return result.message
    } else {
      dispatch(sendMessageFailure(result.error))
      return null
    }
  } catch (e) {
    dispatch(sendMessageFailure(e.toString()))
    return null
  }
}

export const readMessage = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ChatService.readMessage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(countUnreadSuccess(result.unread))
    }
    return result.success
  } catch (e) {
    console.error(e.toString())
  }
}

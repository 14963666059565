import { useState, useEffect } from 'react'
import BlockJob from './BlockJob'

const BlockProjects = ({ user, isOwn, onEdit }) => {
	const [projects, setProjects] = useState([])

	useEffect(() => {
		if (user) {
			setProjects(user.projects)
		}
	}, [user])

	const options = {
		title: 'Projekty',
		anchor: 'projects',
		color: 'blue',
		icon: 'book-list'
	}

	return (
		<BlockJob items={projects} options={options} isOwn={isOwn} onEdit={onEdit} />
	)
}

export default BlockProjects

import { useState } from 'react'
import { emptyFilters, resultTypes } from '@/constants/search'
import { ucfirst } from '@/utils/content.utils'
import arrow from '@/assets/images/search-arrow-down.svg'

import './styles.css'

const SearchFields = ({ cities, activityTypes, onSearch }) => {
	const [query, setQuery] = useState('')
	const [filters, setFilters] = useState(emptyFilters)

	const handleInputChange = (e) => {
		const { value } = e.target
		onSearch({ query: value, filters })
		setQuery(value)
	}

	const handleSelectChange = (e) => {
		const { name, value } = e.target
		setFilters(prevFilters => ({ ...prevFilters, [name]: value }))
	}

	const handleSearch = () => {
		onSearch({ query, filters })
	}

	const clearFilters = () => {
		onSearch({ query, filters: emptyFilters })
		setFilters(emptyFilters)
	}

	return (
		<>
			<div className="block-search-fields">
				<div className="block-search-field">
					<input
						type="search"
						placeholder="wpisz poszukiwany wyraz"
						name="search"
						value={query}
						onChange={handleInputChange}
					/>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="type" value={filters.type} onChange={handleSelectChange}>
								<option value="" disabled>Organizacja/firma/uczelnia</option>
								{Object.entries(resultTypes).map(([key, name]) => (
									<option value={key} key={key}>{ucfirst(name)}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="city" value={filters.city} onChange={handleSelectChange}>
								<option value="" disabled>Miasto</option>
								{Object.values(cities).map((item, index) => (
									<option value={item} key={index}>{item}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="activity_type" value={filters.activity_type} onChange={handleSelectChange}>
								<option value="" disabled>Obszar działania</option>
								{Object.values(activityTypes).map((item, index) => (
									<option value={item} key={index}>{item}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>
				{/*<div className="block-search-field block-search-field-25"><div className="select-field"><select><option>typ projektu</option><option>Value 1</option><option>Value 2</option></select></div></div>*/}
			</div>
			<div className="block-search-buttons">
				<div className="block-search-button">
					<button className="btn btn-primary" onClick={handleSearch}>ZASTOSUJ FILTRY</button>
				</div>
				<div className="block-search-button">
					<button className="btn btn-outline-primary" onClick={clearFilters}>WYCZYŚĆ FILTRY</button>
				</div>
			</div>
		</>
	)
}

export default SearchFields

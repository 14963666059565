import ContentService from '@/services/content.service'

export const GET_AUTH_PAGES_SUCCESS = 'GET_AUTH_PAGES_SUCCESS'
export const GET_AUTH_PAGES_FAILURE = 'GET_AUTH_PAGES_FAILURE'
export const GET_AUTH_PAGE_SUCCESS = 'GET_AUTH_PAGE_SUCCESS'
export const GET_AUTH_PAGE_FAILURE = 'GET_AUTH_PAGE_FAILURE'
export const GET_INTERESTS_SUCCESS = 'GET_INTERESTS_SUCCESS'
export const GET_INTERESTS_FAILURE = 'GET_INTERESTS_FAILURE'

export const getAuthPagesSuccess = (data) => ({ type: GET_AUTH_PAGES_SUCCESS, payload: data })
export const getAuthPagesFailure = (error) => ({ type: GET_AUTH_PAGES_FAILURE, payload: error })
export const getAuthPageSuccess = (data) => ({ type: GET_AUTH_PAGE_SUCCESS, payload: data })
export const getAuthPageFailure = (error) => ({ type: GET_AUTH_PAGE_FAILURE, payload: error })
export const getInterestsSuccess = (data) => ({ type: GET_INTERESTS_SUCCESS, payload: data })
export const getInterestsFailure = (error) => ({ type: GET_INTERESTS_FAILURE, payload: error })

export const getAuthPages = () => async (dispatch) => {
  try {
    const result = await ContentService.getAuthPages()
    console.log(result)
    if (result.success) {
      dispatch(getAuthPagesSuccess(result.auth_pages))
    } else {
      dispatch(getAuthPagesFailure(result.message))
    }
  } catch (e) {
    dispatch(getAuthPagesFailure(e.toString()))
  }
}

export const getAuthPage = (slug) => async (dispatch) => {
  try {
    const result = await ContentService.getAuthPageBySlug(slug)
    console.log(result)
    if (result.success) {
      dispatch(getAuthPageSuccess(result.auth_page))
    } else {
      dispatch(getAuthPageFailure(result.message))
    }
  } catch (e) {
    dispatch(getAuthPageFailure(e.toString()))
  }
}

export const getInterests = () => async (dispatch) => {
  try {
    const result = await ContentService.getInterests()
    console.log(result)
    if (result.success) {
      dispatch(getInterestsSuccess(result.interests))
    } else {
      dispatch(getInterestsFailure(result.message))
    }
  } catch (e) {
    dispatch(getInterestsFailure(e.toString()))
  }
}

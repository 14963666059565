import { Link } from 'react-router-dom'

export const RegisterError = () => 
	<p>
		Ten adres e-mail jest już zarejestrowany. <br />
		Wprowadź inny adres e-mail lub <Link to="/login">zaloguj się</Link>
	</p>

export const LoginError = () => 
	<p>
		Nieprawidłowe hasło lub login. <br />
		Jeśli nie pamiętasz hasła, możesz je zresetować, kliknij <Link to="/forgot-password">TU</Link>
	</p>

export const RecoverPasswordError = () => 
	<p>
		Nie znaleziono podanego adresu e-mail
	</p>

export const ChangePasswordError = () => 
	<p>
		Nie znaleziono podanego tokenu
	</p>

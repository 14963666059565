const ApiError = ({ error }) => {
	return (
		<div className="loginForm-field loginForm-message">
			<div className="loginForm-message-wrap error">
				<div className="loginForm-message-icon">!</div>
				{error}
			</div>
		</div>
	)
}

export default ApiError

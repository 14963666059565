import { useState, useEffect } from 'react'
import { workSystems, contractTypes, minSalary, maxSalary, salaryStep } from '@/constants/offer'
import location from '@/assets/images/offers/location.svg'
import target from '@/assets/images/offers/target.svg'
import computers from '@/assets/images/offers/computers.svg'
import bag from '@/assets/images/offers/bag.svg'
import money from '@/assets/images/offers/money.svg'
import MultiRangeSlider from 'multi-range-slider-react'
import arrow from '@/assets/images/search-arrow-down.svg'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import TextEditor from '@/components/Common/Edit/TextEditor'
import offerSchema from '@/validations/offer.schema'

const EditForm = ({ offer = null, members = [], onSave }) => {
	const [data, setData] = useState({
		title: '',
		city: '',
		specialization: '',
		work_system: null,
		contract_type: null,
		salary_from: minSalary,
		salary_to: maxSalary,
		ref_user_id: null,
		content: ''
	})

	const [errors, setErrors] = useState({
		title: false,
		city: false,
		specialization: false,
		content: false
	})

	const [refUser, setRefUser] = useState({
		id: null,
		avatar_image: null,
		name: '(brak danych)'
	}) 

	useEffect(() => {
		if (offer) {
			const {
				title,
				city,
				specializations,
				work_system,
				contract_type,
				salary_from,
				salary_to,
				ref_user_id,
				ref_user,
				content
			} = offer

			setData({
				title,
				city,
				specialization: specializations[0],
				work_system,
				contract_type,
				salary_from,
				salary_to,
				ref_user_id,
				content
			})

			if (ref_user) {
				const { avatar_image, firstname, lastname } = ref_user.profile
				setRefUser({ id: ref_user.id, avatar_image, name: `${firstname} ${lastname}` })
			}
		}
	}, [offer])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	const switchers = [
		{ param: 'work_system', icon: computers, label: 'System pracy (wybierz)', items: workSystems },
		{ param: 'contract_type', icon: bag, label: 'Rodzaj umowy (wybierz)', items: contractTypes },
	]

	const handleSwitcherChange = (param, key) => {
		setData({ ...data, [param]: key })
	}

	const [salaryFromValue, setSalaryFromValue] = useState(minSalary)
	const [salaryToValue, setSalaryToValue] = useState(maxSalary)

	const handleSalaryInputChange = (e) => {
		const { name, value } = e.target

		if (name === 'salary_from') {
			setSalaryFromValue(Number(value))
			if (value >= minSalary && value <= data.salary_to) {
				setData(prevData => ({
					...prevData,
					salary_from: Number(value)
				}))				
			}
		}

		if (name === 'salary_to') {
			setSalaryToValue(Number(value))
			if (value >= data.salary_from && value <= maxSalary) {
				setData(prevData => ({
					...prevData,
					salary_to: Number(value)
				}))				
			}
		}
	}

	const handleSalaryRangeChange = (e) => {
		setData(prevData => ({
			...prevData,
			salary_from: e.minValue,
			salary_to: e.maxValue
		}))
	}

	useEffect(() => {
	    setSalaryFromValue(data.salary_from)
	    setSalaryToValue(data.salary_to)
	}, [data.salary_from, data.salary_to])

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

	const [refUserDropdownVisible, setRefUserDropdownVisible] = useState(false)

	const toggleRefUserDropdown = () => {
		setRefUserDropdownVisible(!refUserDropdownVisible)
	}

	const selectRefUser = (id) => {
		const user = members.find(member => member.id === id)
		if (user) {
			const { avatar_image, firstname, lastname } = user.profile
			setRefUser({
				id,
				avatar_image,
				name: `${firstname} ${lastname}`
			})
			setData({ ...data, ref_user_id: id })
			setRefUserDropdownVisible(false)
		}
	}

	const handleContentChange = (content) => {
		setData({ ...data, content })
	}

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateForm = async (formData) => {
		try {
			await offerSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

	const saveOffer = async (active) => {
	    const formValid = await validateForm(data)
	    if (formValid === true) {
	    	onSave({ ...data, specializations: [data.specialization], active })
    	}
	}

	return (
		<div className="block-offer-edit-form">
			<div className="block-offer-edit-form-fields">
				<div className="block-offer-edit-form-field block-offer-edit-form-field-title">
					<div className="block-offer-edit-form-field-icon"></div>
					<div className="block-offer-edit-form-field-label">Tytuł oferty</div>
					<div className="block-offer-edit-form-field-input">
						<input
							type="text"
							name="title"
							value={data.title}
							placeholder="wpisz tytuł oferty"
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<div className="block-offer-edit-form-field block-offer-edit-form-field-location">
					<div className="block-offer-edit-form-field-icon">
						<img src={location} alt="" />
					</div>
					<div className="block-offer-edit-form-field-label">Miejscowość</div>
					<div className="block-offer-edit-form-field-input">
						<input
							type="text"
							name="city"
							value={data.city}
							placeholder="wpisz"
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<div className="block-offer-edit-form-field block-offer-edit-form-field-specialization">
					<div className="block-offer-edit-form-field-icon">
						<img src={target} alt="" />
					</div>
					<div className="block-offer-edit-form-field-label">Specjalizacja</div>
					<div className="block-offer-edit-form-field-input">
						<input
							type="text"
							name="specialization"
							value={data.specialization}
							placeholder="wpisz np: marketing i sprzedaż"
							onChange={handleInputChange}
						/>
					</div>
				</div>
				{switchers.map((switcher, index) => {
					const { param, icon, label, items } = switcher
					return (
						<div className="block-offer-edit-form-field" key={index}>
							<div className="block-offer-edit-form-field-icon">
								<img src={icon} alt="" />
							</div>
							<div className="block-offer-edit-form-field-label">{label}</div>
							<div className="block-offer-edit-form-field-switcher">
								{Object.entries(items).map(([key, name]) => (
									<div
										className={`${data[param] == key ? 'active' : ''}`}
										key={key}
										onClick={() => handleSwitcherChange(param, key)}
									>{name}</div>
								))}
							</div>
						</div>
					)
				})}
				<div className="block-offer-edit-form-field block-offer-edit-form-field-salary">
					<div className="block-offer-edit-form-field-icon">
						<img src={money} alt="" />
					</div>
					<div className="block-offer-edit-form-field-label">Widełki płacowe</div>
					<div className="block-offer-edit-form-field-salary-choose">
						<span>wpisz wartości lub zaznacz na osi</span>
						<div className="block-offer-edit-form-field-salary-choose-slider">
							<MultiRangeSlider
								min={minSalary}
								max={maxSalary}
								step={salaryStep}
								stepOnly={true}
								minValue={data.salary_from}
								maxValue={data.salary_to}
								label={false}
								ruler={false}
								style={{ border: 'none', boxShadow: 'none', padding: '0' }}
								barLeftColor="white"
								barRightColor="#FFFFFF"
								barInnerColor="#D9D9D9"
								onInput={(e) => handleSalaryRangeChange(e)}
							/>
						</div>
						<div className="block-offer-edit-form-field-salary-choose-fields">
							od <input value={salaryFromValue} name="salary_from" onChange={handleSalaryInputChange} />
							do <input value={salaryToValue} name="salary_to" onChange={handleSalaryInputChange} />
						</div>
					</div>
				</div>
				<div className="block-offer-edit-form-field block-offer-edit-form-field-ref">
					<div className="block-offer-edit-form-field-icon"></div>
					<div className="block-offer-edit-form-field-label">Osoba odpowiedzialna</div>
					<div className="block-offer-edit-form-field-ref-select">
						<span className="form-field-arrow">
							<div
								className="block-offer-edit-form-field-ref-select-selected"
								onClick={toggleRefUserDropdown}
							>
								<div className="block-offer-edit-form-field-ref-select-photo">
									<div>
										<img src={getImage(refUser.avatar_image)} alt="" />
									</div>
								</div>
								<span>{refUser.name}</span>
							</div>
							<span className="button" onClick={toggleRefUserDropdown}>
								<img src={arrow} alt="" />
							</span>
						</span>
						{refUserDropdownVisible && <div className="block-offer-edit-form-field-ref-select-dropdown">
							{members.map((member, index) => {
								const { id, profile } = member
								const { firstname, lastname, avatar_image } = profile
								return (
									<div
										className={`block-offer-edit-form-field-ref-select-dropdown-item ${refUser.id === id ? 'active' : ''}`}
										key={index}
										onClick={() => selectRefUser(id)}
									>
										<div className="block-offer-edit-form-field-ref-select-photo">
											<div>
												<img src={getImage(avatar_image)} alt="" />
											</div>
										</div>
										<span>{firstname} {lastname}</span>
									</div>
								)
							})}
						</div>}
					</div>
				</div>
				<div className="block-offer-edit-form-field block-offer-edit-form-field-content">
					<div>
						<div className="block-offer-edit-form-field-icon"></div>
						<div className="block-offer-edit-form-field-label">Treść ogłoszenia</div>
					</div>
					<div>
						<TextEditor content={data.content} onContentChange={handleContentChange} />
					</div>
				</div>				
			</div>
			<div className="block-offer-edit-form-buttons">
				<div>
					<button
						className="btn btn-primary btn-icon-right btn-icon-check"
						onClick={() => saveOffer(true)}
					>
						<span>ZATWIERDŹ / OPUBLIKUJ</span>
					</button>
					<button
						className="btn btn-outline-primary"
						onClick={() => saveOffer(false)}
					>
						<span>ZAPISZ SZKIC</span>
					</button>
				</div>
				<div>
					<button
						className="btn btn-outline-primary btn-icon-right btn-icon-settings"
					>
						<span>OPCJE PROMOWANIA</span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default EditForm

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMyOrganizationById } from '@/actions/organization'
import BlockDashboardInfo from '@/components/Organization/BlockDashboardInfo'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockDashboardBudget from '@/components/Organization/BlockDashboardBudget'
import BlockDashboardProjects from '@/components/Organization/BlockDashboardProjects'

const OrganizationDashboardPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const myOrganizationLoading = useSelector(state => state.organization.myOrganizationLoading)
    const myOrganization = useSelector(state => state.organization.myOrganization)

    useEffect(() => {
        if (id) dispatch(getMyOrganizationById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!myOrganization && !myOrganizationLoading) {
        	navigate('/404')
        }
    }, [myOrganizationLoading, myOrganization, navigate])

	return (
		<>
			<BlockDashboardInfo organization={myOrganization} />
			<BlockMembers
				entity={myOrganization}
				title="Zespół"
				owlItems={4}
				isShort={true}
				followButton={false}
			/>
			<BlockDashboardBudget organization={myOrganization} />
			<BlockDashboardProjects organization={myOrganization} />
		</>
	)
}

export default OrganizationDashboardPage

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createOffer, updateOffer } from '@/actions/offer'
import { getMyCompanyOffers } from '@/actions/company'
import { Link, useNavigate } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import EditForm from './EditForm'

import './styles.css'

const OfferEditBlock = ({ mode, company, offer = null }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [members, setMembers] = useState([])

	useEffect(() => {
		if (company) {
			setMembers(company.members)
		}
	}, [company])

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

    const goBack = () => {
        navigate(-1)
    }

    const onSave = async (data) => {
    	const payload = {
    		company_id: company.id,
    		offer_id: offer ? offer.id : null,
    		...data
    	}
    	let success
    	if (mode === 'create') {
    		success = await dispatch(createOffer(payload))
    	} else if (mode === 'edit') {
    		success = await dispatch(updateOffer(payload))
    	}
    	if (success) {
    		await dispatch(getMyCompanyOffers(company.id))
    		navigate(`/offers/company/${company.id}`)
    	}
    }

	if (!company) return

	return (
		<div className="block block-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-offers-company-head">
					<div className="block-offers-company-logo">
						<img src={getImage(company.logo_image)} alt="" />
					</div>
					<div className="block-search-heading">
						<h1>Panel ofert {company.name}</h1>
						<h2>Edycja / tworzenie oferty</h2>
					</div>
				</div>
				<EditForm offer={offer} members={members} onSave={onSave} />
			</div>
		</div>
	)
}

export default OfferEditBlock

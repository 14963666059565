import { useState, useEffect } from 'react'
import { formatPeriod } from '@/utils/datetime.utils'

import './styles.css'

const JobItem = ({ item, color }) => {
	const { title, company, date_from, date_to, description, features } = item

	const [open, setOpen] = useState(false)

	const toggleContent = () => {
		setOpen(!open)
	}

	return (
		<div className={`block-works-item block-works-item_style-bg ${color} ${open ? 'show' : ''}`}>
			<div className="block-works-item_head">
				<span className="block-works-item_title">{title}</span>
				<span className="block-works-item_company">{company}</span>
				<span className="block-works-item_date">{formatPeriod(date_from, date_to)}</span>
				<span className={`block-works-item_status ${date_to ? 'finish' : 'refresh'}`}></span>
			</div>
			<div className="block-works-item_body">
				<div className="block-works-item_content">
					{description}
				</div>
				<div className="block-works-item_list">
					<ul>
						{features.filter(feature => feature)
								 .map((feature, index) => <li key={index}><span>{feature}</span></li>)}
					</ul>
				</div>
			</div>
			<div className="block-works-item_more">
				<a
					style={{ cursor: 'pointer' }}
					onClick={toggleContent}
				>
					{!open && <span>rozwiń</span>}
					{open && <span>zwiń</span>}
				</a>
			</div>
		</div>
	)
}

const BlockJob = ({ items, options, isOwn, onEdit }) => {
	const { title, color, icon } = options

	const [itemColor, setItemColor] = useState('')

	useEffect(() => {
		if (color) {
			if (color === 'red') setItemColor('yellow')
			else setItemColor(color)
		}
	}, [color])

	return (
		<div className="block block-works">
			{isOwn && <div className="block-edit">
				{/*<Link to={`/user/edit?block=${anchor}`}>edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			<div className="block-wrap">
				<div className={`block-heading ${color} block-heading-icon ${icon}`}>
					<h2>{title}</h2>
				</div>
				<div className="block-works-items">
					{items.map((item, index) => 
						<JobItem item={item} key={index} color={!isOwn ? itemColor : ''} />)
					}
					{items.length === 0 && <span>(brak danych)</span>}
				</div>
			</div>
		</div>
	)
}

export default BlockJob

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const RootPage = () => {
	const navigate = useNavigate()

	const userLoading = useSelector(state => state.auth.userLoading)
	const user = useSelector(state => state.auth.user)

	useEffect(() => {
		if (user) {
			navigate('/user/dashboard')
		} else if (!userLoading) {
			navigate('/login')
		}
	}, [userLoading, user, navigate])

	return null
}

export default RootPage

import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialMessages, getOlderMessages, sendMessage, readMessage } from '@/actions/chat'
import echo from '@/services/echo'
import messagesIcon from '@/assets/images/messages.svg'
import ChatUserItem from './ChatUserItem'
import ChatMessage from './ChatMessage'

const ChatDialog = ({ dialog, onClose }) => {
	const dispatch = useDispatch()
	const containerRef = useRef(null)
	const messageRefs = useRef([])

  	const authUser = useSelector(state => state.auth.user)
	const lastMessages = useSelector(state => state.chat.lastMessages)
	const olderMessages = useSelector(state => state.chat.olderMessages)

	const [user, setUser] = useState(null)
	const [messages, setMessages] = useState([])
	const [oldMessages, setOldMessages] = useState([])
	const [loadMore, setLoadMore] = useState(true)
	const [content, setContent] = useState('')

	useEffect(() => {
		if (dialog) {
			setMessages([])
			setUser(dialog.collocutor)
			dispatch(getInitialMessages({ dialog_id: dialog.id }))
		}
	}, [dispatch, dialog])

	useEffect(() => {
		if (!dialog || !authUser) return

        echo.channel(`chat.${authUser.id}`)
            .listen('.message', (e) => {
            	console.log(e)
                if (e.message.sender_id === dialog.collocutor.user_id) {
					setMessages(prevMessages => [ ...prevMessages, e.message ])
                }
            })

        /*return () => {
            echo.leaveChannel(`chat.${authUser.id}`)
        }*/
    }, [dialog, authUser])

	useEffect(() => {
		if (lastMessages.length) {
			setMessages(lastMessages)
		}
	}, [lastMessages])

	useEffect(() => {
	    const scrollToBottom = () => {
			if (containerRef.current) {
				containerRef.current.scrollTo({
					top: containerRef.current.scrollHeight,
					// behavior: 'smooth'
				})
			}
	    }

		const firstUnreadIndex = messages.findIndex(message => !message.is_own && !message.read)

		if (firstUnreadIndex !== -1) {
			const firstUnreadElement = messageRefs.current[firstUnreadIndex]
			if (containerRef.current && firstUnreadElement) {
				const elementOffset = firstUnreadElement.offsetTop
		        const elementHeight = firstUnreadElement.offsetHeight
		        const containerHeight = containerRef.current.clientHeight
		        console.log(elementOffset)
				console.log(elementHeight)
				console.log(containerHeight)
		        containerRef.current.scrollTo({
		          top: elementOffset/* - containerHeight + elementHeight*/,
		          // behavior: 'smooth'
		        })
			}
		} else {
		    scrollToBottom()
		}

		const observer = new IntersectionObserver(entries => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const id = entry.target.getAttribute('data-id')
					const isRead = Number(entry.target.getAttribute('data-read'))
					// console.log(id, isRead)

					if (!isRead) {
						dispatch(readMessage({ id }))
						entry.target.setAttribute('data-read', 1)
					}
				}
			})
		}, { threshold: 0.1 })

		messageRefs.current.forEach((ref) => ref && observer.observe(ref))

	    return () => {
    		messageRefs.current.forEach((ref) => ref && observer.unobserve(ref))
    	}
	}, [messages])

	const loadMoreMessages = async () => {
	    if (messages.length === 0) return

	    let last_message_id = messages[0].id
	    if (oldMessages.length) last_message_id = oldMessages[0].id

	    const loadedMessages = await dispatch(getOlderMessages({
	    	dialog_id: dialog.id,
	    	last_message_id
	    }))

		if (!loadedMessages.length) {
			setLoadMore(false)
		}
	}

	useEffect(() => {
	    const container = containerRef.current

	    if (container && loadMore) {
	    	let isLoading = false

		    const handleScroll = async () => {
		        if (container.scrollTop <= 10 && !isLoading) {
		        	isLoading = true
		            await loadMoreMessages()
		            isLoading = false
		            container.scrollTop = 20
		        }
		    }

		    container.addEventListener('scroll', handleScroll)

		    return () => {
		        container.removeEventListener('scroll', handleScroll)
		    }
	    }
	}, [messages, oldMessages, loadMore])

	useEffect(() => {
		if (olderMessages.length) {
			setOldMessages(prevOldMessages => [...olderMessages, ...prevOldMessages])
		}
	}, [olderMessages])

	const handleKeyDown = (e) => {
	    if (e.key === 'Enter') {
	    	handleSendClick()
	    }
	}

	const handleContentChange = (e) => {
		setContent(e.target.value)
	}

	const handleSendClick = async () => {
		if (content) {
			const newMessage = await dispatch(sendMessage({ receiver_id: user.user_id, content }))

			if (newMessage) {
				setContent('')
				setMessages(prevMessages => [ ...prevMessages, newMessage ])
			}
		}
	}

	if (!dialog) return null

	return (
		<div className="block block-chat-dialog">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={onClose}><span></span></a>
			</div>
			<div className="block-wrap">
				<div>
					<div className="block-chat-dialog-heading">
						<img src={messagesIcon} alt="" />
						<span>Napisz wiadomość do</span>
						<ChatUserItem position="dialog" user={user} isSelected={true} />
					</div>
					<div className="block-chat-dialog-line top"></div>
				</div>
				<div
					className={`block-chat-dialog-messages ${messages.length === 0 ? 'no-messages' : ''}`}
					ref={containerRef}
				>
					{oldMessages.length === 0 && messages.length === 0 && <span>Brak wiadomości</span>}
					{oldMessages.length !== 0 && oldMessages.map((message, index) => (
						<ChatMessage message={message} key={index} />
					))}
					{messages.length !== 0 && messages.map((message, index) => (
						<ChatMessage
							ref={(el) => (messageRefs.current[index] = el)}
							message={message}
							key={index}
						/>
					))}
				</div>
				<div>
					<div className="block-chat-dialog-line bottom"></div>
					<form className="block-chat-dialog-form">					
						<div className="form-fields">
							<div className="form-field">
								<textarea
									placeholder="Napisz wiadomość - max 2000 znaków"
									value={content}
									onKeyDown={handleKeyDown}
									onChange={handleContentChange}
								></textarea>
							</div>
						</div>
						<div className="block-editProfile-confirm">
							<button
								type="button"
								className="btn btn-primary btn-icon-right btn-icon-check"
								onClick={handleSendClick}
							>
								<span>WYŚLIJ</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ChatDialog

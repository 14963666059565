import { useDispatch } from 'react-redux'
import { recoverPassword } from '@/actions/auth'
import ForgotPasswordForm from '@/components/Auth/Form/ForgotPasswordForm'

const ForgotPasswordPage = ({ texts }) => {
	const dispatch = useDispatch()

	const onSubmit = (payload) => {
		dispatch(recoverPassword(payload))
	}

	return (		
		<div className="loginForm-section restoreForm-section">
			<div className="container">
				<ForgotPasswordForm texts={texts} onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default ForgotPasswordPage

import { useState, useEffect } from 'react'

const TabBlock = ({ id, active }) => {
	return (
		<div className={`block-budget_tabs-block tab-${id} ${active ? 'active' : ''}`}>
			<div className="block-budget_charts">
				<div className="block-budget_charts-line">
					<span style={{ background: '#C0202E', width: '100%' }}></span>
					<span style={{ background: '#F3B119', width: '70%' }}></span>
					<span style={{ background: '#F2D70F', width: '42.5%' }}></span>
					<span style={{ background: '#63B2E3', width: '30%' }}></span>
					<span style={{ background: '#344154', width: '10%' }}></span>
				</div>
				<div className="block-budget_charts-items">
					<div className="block-budget_charts-item">
						<div className="block-budget_charts-item_line"><span style={{ background: '#C0202E', width: '100%' }}></span></div>
						<p className="block-budget_charts-item_info"><span>40%</span> przychody organizacji</p>
					</div>
					<div className="block-budget_charts-item">
						<div className="block-budget_charts-item_line"><span style={{ background: '#F3B119', width: '70%' }}></span></div>
						<p className="block-budget_charts-item_info"><span>28%</span> darowizny opodatkowane</p>
					</div>
					<div className="block-budget_charts-item">
						<div className="block-budget_charts-item_line"><span style={{ background: '#F2D70F', width: '42.5%' }}></span></div>
						<p className="block-budget_charts-item_info"><span>17%</span> stałe przychodzy z czegoś tam </p>
					</div>
					<div className="block-budget_charts-item">
						<div className="block-budget_charts-item_line"><span style={{ background: '#63B2E3', width: '30%' }}></span></div>
						<p className="block-budget_charts-item_info"><span>12%</span> zwroty z podatków</p>
					</div>
					<div className="block-budget_charts-item">
						<div className="block-budget_charts-item_line"><span style={{ background: '#344154', width: '10%' }}></span></div>
						<p className="block-budget_charts-item_info"><span>4%</span> darowizny prywatne</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const BlockDashboardBudget = ({ organization }) => {
	const [budget, setBudget] = useState(0)
	const [openTab, setOpenTab] = useState(1)

	useEffect(() => {
		if (organization) {
			setBudget(organization.projects.reduce(
				(sum, item) => sum = sum + item.budget, 0
			))
		}
	})

	const tabs = [
		{ id: 1, title: 'wydatki' },
		{ id: 2, title: 'przychody' },
	]

	return (
		<div className="block block-budget">
			<div className="block-wrap block-budget_wrap">
				<div className="block-budget_left">
					<div className="block-heading">
						<h2>Budżet 2024</h2>
					</div>
					<p className="block-budget_total-summ">
						całkowity budżet
						<span>{new Intl.NumberFormat('pl-PL').format(budget)}</span>
					</p>
					<ul className="block-budget_summ">
						<li>planowane wydatki <span>7300</span></li>
						<li>saldo <span>10 000</span></li>
						<li>planowane przychody <span>5000</span></li>
						<li>bilans <span>5000</span></li>
					</ul>
				</div>
				<div className="block-budget_right">
					<div className="block-heading">
						<h2>Struktura budżetu 2024</h2>
					</div>
					<div className="block-budget_tabs">
						<ul className="block-budget_tabs-nav">
							{tabs.map(item => {
								const { id, title } = item
								return (
									<li
										className={`${openTab === id ? 'active' : ''}`}
										key={id}
										style={{ cursor: 'pointer' }}
									>
										<a onClick={() => setOpenTab(id)}>{title}</a>
									</li>
								)
							})}
						</ul>
						<div className="block-budget_tabs-blocks">
							{tabs.map(item => {
								const { id } = item
								return <TabBlock id={id} active={openTab === id} key={id} />
							})}
						</div>
					</div>
				</div>
				<div className="block-budget_button">
					<a className="btn btn-primary">ZARZĄDZAJ FINANSMI I ADMINISTRACJĄ</a>
				</div>
			</div>
		</div>
	)
}

export default BlockDashboardBudget

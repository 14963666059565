import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountTypes } from '@/actions/auth'
import { escape } from '@/utils/content.utils'
import { AccountTypeHeading as Heading } from './Headings'

const AccountTypeItem = ({ item, onSelected }) => {
	const { order: key, color, site_name, description } = item

	return (		
		<div className={`services-item services-item-${key}`} onClick={() => onSelected(key)}>
			<a className="services-item__wrap">
				<div className={`services-item__image ${color}`}></div>
				<p className="services-item__subtitle">Konto Użytkownika</p>
				<h3 className="services-item__title">{site_name}</h3>
				<p
					className="services-item__description"
					dangerouslySetInnerHTML={{ __html: description }}
				></p>
				<div className={`services-item__button ${color}`}><span>WYBIERZ</span></div>
			</a>
		</div>
	)
}

const SelectAccountType = ({ texts, onAccountTypeSelected }) => {
	const dispatch = useDispatch()

	const accountTypes = useSelector(state => state.auth.accountTypes)

	useEffect(() => {
		dispatch(getAccountTypes())
	}, [dispatch])

	const [title, setTitle] = useState(null)
	const [subtitle, setSubtitle] = useState(null)

	useEffect(() => {
		if (texts) {
			setTitle(escape(texts.title))
			setSubtitle(escape(texts.subtitle))
		}
	}, [texts])

	const onSelected = (key) => {
		onAccountTypeSelected(key)
	}

	return (
		<>
			<Heading title={title} subtitle={subtitle} />
			<div className="services-items">
				{accountTypes.map((item, index) => 
					<AccountTypeItem item={item} key={index} onSelected={onSelected} />
				)}
			</div>
		</>
	)
}

export default SelectAccountType

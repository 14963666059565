import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { pl } from 'date-fns/locale/pl'

const DateEditor = ({ wrapperClassName, className, innerHtml, name, placeholder, selected, onChange }) => {
	const CustomInput = forwardRef(
		({ value, onClick, onChange }, ref) => (
			<>
				<input
					ref={ref}
					className={className}
					type="text"
					placeholder={placeholder}
					value={value}
					onClick={onClick}
					onChange={onChange}
				/>
				{innerHtml}
			</>
		)
	)

    const handleDateChange = (value) => {
        onChange({ name, value })
    }

    return (
        <DatePicker
            wrapperClassName={wrapperClassName}
            locale={pl}
            showYearDropdown
            dateFormat="dd.MM.yyyy"
            placeholderText={placeholder}
            selected={selected}
            onChange={handleDateChange}
            maxDate={new Date()}
            customInput={<CustomInput />}
        />
    )
}

export default DateEditor

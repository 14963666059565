import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { register } from '@/actions/auth'
import SelectAccountType from '@/components/Auth/Form/SelectAccountType'
import RegisterForm from '@/components/Auth/Form/RegisterForm'

const RegisterPage = ({ texts }) => {
	const dispatch = useDispatch()

	const [accountType, setAccountType] = useState(null)

	const onAccountTypeSelected = (key) => {
		setAccountType(key)
	}

	const onSubmit = (payload) => {
		dispatch(register({ ...payload, account_type: accountType }))
	}

	return (
		<div className={`${accountType ? 'loginForm-section' : 'services-section'}`}>
			<div className="container">
				{!accountType && 
					<SelectAccountType
						texts={texts.connected_pages['select-account-type']}
						onAccountTypeSelected={onAccountTypeSelected}
					/>
				}
				{accountType && <RegisterForm texts={texts} onSubmit={onSubmit} />}
			</div>
		</div>
	)
}

export default RegisterPage

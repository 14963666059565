export const LoginHeading = ({ title }) => 
	<div className="heading">
		<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
	</div>

export const RegisterHeading = ({ title, subtitle }) => 
	<div className="heading">
		<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
		<h2 dangerouslySetInnerHTML={{ __html: subtitle }}></h2>
	</div>

export const AccountTypeHeading = ({ title, subtitle }) => 	
	<div className="heading">
		<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
		<h2 dangerouslySetInnerHTML={{ __html: subtitle }}></h2>
	</div>

export const ForgotPasswordHeading = ({ title, subtitle }) => 	
	<div className="heading restoreForm-heading">
		<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
		<p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
	</div>

export const ChangePasswordHeading = ({ title, subtitle }) => 	
	<div className="heading changePasswordForm-heading">
		<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
		<p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
	</div>

export const PasswordChangedHeading = ({ title, subtitle }) => 
	<h1 className="changePasswordOK-heading">
		<div dangerouslySetInnerHTML={{ __html: title }}></div>
		<span>Udało się!</span> <br />
		<div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
	</h1>

import api from './api'

class OfferService
{
	async getCities(token) {
		try {
			const response = await api.get('/offer/cities', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][getCities][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getActivityTypes(token) {
		try {
			const response = await api.get('/offer/activity-types', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][getActivityTypes][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async searchOffers(payload, token) {
		try {
			const response = await api.post('/offer/search', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][searchOffers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getOffer(id, token) {
		try {
			const response = await api.get(`/offer/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][getOffer][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getMyOffers(token) {
		try {
			const response = await api.get('/offer/my', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][getMyOffers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async createOffer(payload, token) {
		try {
			const response = await api.post('/offer/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][createOffer][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async updateOffer(payload, token) {
		try {
			const response = await api.post('/offer/update', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][updateOffer][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async updateOfferParam(payload, token) {
		try {
			const response = await api.post('/offer/update-param', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OfferService][updateOfferParam][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new OfferService()

import * as yup from 'yup'
import { workSystems, contractTypes, minSalary, maxSalary } from '@/constants/offer'

const offerSchema = yup.object().shape({
  title: yup.string()
    .required(),
  city: yup.string()
    .required(),
  specialization: yup.string()
    .required(),
  work_system: yup.number()
    .required()
    .min(1)
    .max(Object.keys(workSystems).length),
  contract_type: yup.number()
    .required()
    .min(1)
    .max(Object.keys(contractTypes).length),
  salary_from: yup.number()
    .required()
    .min(minSalary)
    .max(maxSalary),
  salary_to: yup.number()
    .required()
    .min(minSalary)
    .max(maxSalary),
  content: yup.string()
    .required(),    
})

export default offerSchema

import * as yup from 'yup'
// import * as C from '../constants/auth'

const minPasswordLength = 6
const maxPasswordLength = 32

const changePasswordSchema = yup.object().shape({
  password: yup.string()
    .min(minPasswordLength, '')
    .max(maxPasswordLength, '')
    .required(),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], ''),
})

export default changePasswordSchema

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetError } from '@/actions/auth'
import { useLocation } from 'react-router-dom'
import EllipseLeft from './EllipseLeft'
import ImageRight from './ImageRight'
import { ChangePasswordHeading as Heading } from './Headings'
import changePasswordSchema from '@/validations/change-password.schema'
import ApiError from './ApiError'
import { ChangePasswordError } from './Errors'

const FormFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<>
			{params.map((param, index) => {
				const { name, type, label, value, error } = param
				return (
					<div
						className={`loginForm-field loginForm-field-50${error ? ' form-field-error' : ''}`}
						key={index}
					>
						<input
							type={type}
							name={name}
							placeholder={label}
							value={value}
							autoComplete="new-password"
							required
							onChange={handleInputChange}
						/>
						{error && <span className="form-field-icon">!</span>}
					</div>
				)
			})}
		</>
	)
}

const ChangePasswordForm = ({ texts, onSubmit }) => {
	const dispatch = useDispatch()
  const location = useLocation()

	const apiError = useSelector(state => state.auth.error)
	const recoverEmail = useSelector(state => state.auth.recoverEmail)

	useEffect(() => {
		dispatch(resetError())
	}, [dispatch, location.pathname])

	const [data, setData] = useState({
		password: '',
		password_confirmation: ''
	})

  const [errors, setErrors] = useState({
    password: false,
    password_confirmation: false,
  })

	const params = [
		{ name: 'password', type: 'password', label: 'nowe hasło', value: data.password, error: errors.password },
		{ name: 'password_confirmation', type: 'password', label: 'powtórz nowe hasło', value: data.password_confirmation, error: errors.password_confirmation },
	]

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
    setErrors({ ...errors, [name]: false })
	}

  const setError = (name) => {
    setErrors({ ...errors, [name]: true })
    return false
  }

  const validateForm = async (formData) => {
    try {
      await changePasswordSchema.validate(formData, { abortEarly: false })
      return true
    } catch (errors) {
    	// console.error(errors.inner)
      let error = errors.inner[0]
      return setError(error.params.path)
    }
  }

	const handleFormSubmit = async () => {
    const formValid = await validateForm(data)
    if (formValid === true) {
			onSubmit({ ...data, email: recoverEmail })
    }
	}

	return (
		<form>
			<div className="loginForm-wrap">
				<EllipseLeft />
				<div className="loginForm-left">
					<Heading title={texts.title} subtitle={texts.subtitle.replace('{mail}', recoverEmail)} />
					<div className="loginForm-fields loginForm-fields-col">
						<FormFields params={params} onInputChange={onInputChange} />
						<div className="loginForm-field loginForm-field-btn">
							<button type="button" disabled={apiError} onClick={handleFormSubmit}>ZATWIERDŹ NOWE HASŁO</button>
						</div>
						{apiError && <ApiError error={<ChangePasswordError />} />}
					</div>
				</div>
				<ImageRight />
			</div>
		</form>
	)
}

export default ChangePasswordForm

import { useState, useEffect } from 'react'

import './styles.css'

const BlockAchievements = ({ user, isOwn, onEdit }) => {
	const [achievements, setAchievements] = useState([])

	useEffect(() => {
		if (user) {
			setAchievements(user.achievements)
		}
	}, [user])

	return (
		<div className="block block-achievements">
			{isOwn && <div className="block-edit">
				{/*<Link to="/user/edit?block=achievements">edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>Osiągnięcia</h2>
				</div>
				<div className="block-achievements-items">
					{achievements.map((item, index) => {
						const { content } = item
						return (
							<div className="block-achievements-item" key={index}>
								<p className="block-achievements-item_title">{content}</p>
								<span className="block-achievements-item_sep"></span>
								<span className="block-achievements-item_check"></span>
							</div>
						)
					})}
					{achievements.length === 0 && <span>(brak danych)</span>}
				</div>
			</div>
		</div>
	)
}

export default BlockAchievements

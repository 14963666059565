import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveProjects } from '@/actions/user'
import BlockEditJob from './BlockEditJob'

const BlockEditProjects = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const [projects, setProjects] = useState([])

	useEffect(() => {
		if (user) {
			setProjects(user.projects)
		}
	}, [user])

	const params = {
		title: { label: 'Nazwa projektu', placeholder: 'wpisz, np: Zjazd studentów zaocznych' },
		company: { label: 'firma/organizacja', placeholder: 'wpisz nazwę w której powstał projekt' },
		date_from: { label: 'okres', placeholder: 'data start D/M/R' },
		date_to: { label: 'jeśli trwa nie wypełniaj daty stop', placeholder: 'data stop D/M/R' },
		description: { label: null, placeholder: 'Krótki opis projektu - max 400 znaków' },
		features: { label: 'główne cele/sukcesy', placeholder: 'wpisz najważniejsze cele lub efekty' }
	}

	const options = {
		title: 'Projekty',
		anchor: 'projects',
		color: 'blue',
		icon: 'book-list',
		btnClass: 'secondary'
	}

	const onSubmit = async (projects) => {
		await dispatch(saveProjects({ projects }))
		onSave()
	}

	return (
		<BlockEditJob
			params={params}
			items={projects}
			options={options}
			onSubmit={onSubmit}
		/>
	)
}

export default BlockEditProjects

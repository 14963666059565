import { useState, useEffect } from 'react'

const BlockGoals = ({ project }) => {
	const [goals, setGoals] = useState([])

	useEffect(() => {
		if (project) {
			setGoals(project.goals)
		}
	}, [project])

	return (
		<div className="block block-goals">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>cele projektu</h2>
				</div>
				<ul className="block-goals-items">
					{goals.map((goal, index) => <li key={index}>{goal.content}</li>)}
				</ul>
			</div>
		</div>
	)
}

export default BlockGoals
